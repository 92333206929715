import React from 'react';
import axios from 'axios';

import * as Utility from '../utilities/Utility';

class UpdateLatLng extends React.Component {

    componentDidMount(){
        axios.post(Utility.getServerUrl() + '/updateLatLong')
        .then(res => {
            console.log("result" + res.data);
        })
    }

    render(){
        return(
            <p>Updating Check Service Log.......</p>
        )
    }

}    export default UpdateLatLng;