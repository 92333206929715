import React from 'react';
import axios from 'axios';
import * as Utility from '../../utilities/Utility'
import * as GlobalFunction from '../../utilities/GlobalFunction'

class Contact extends React.Component {
    constructor(props) {
        super()
        this.state = {
            name: '',
            email: '',
            message: '',
            statusError: '',
            member: null
        }
    }

    reset = () => {
        console.log("reset");
        this.setState({
            message: '',
            statusError: '',
        });
        let member = GlobalFunction.getMember();
        if (member) {
            this.setState({ email: member.email, name: member.name, member: member })
        }
        else{
            this.setState({
                email: '',
                name: '',
            }); 
        }
    }

    handleSubmitForm = (e) => {
        e.preventDefault();
        const { name, email, message } = this.state;

        axios.post(Utility.getServerUrl() + '/contactUs', {
            name: name,
            email: email,
            message: message
        })
            .then(res => {
                console.log(res.data)
                this.setState({ statusError: res.data })
            })
    }

    render() {
        const { statusError, email, member } = this.state
        let modalRender = "";
        if (statusError === "OK") {
            modalRender = "modal-dialog modal-dialog-centered"
        }
        else {
            modalRender = "modal-dialog modal-lg modal-dialog-centered";
        }

        return (
            <div id="contact-modal" className="modal fade" tabIndex="-1" role="dialog"
                aria-labelledby="contact-modalLabel" aria-hidden="true">
                <div className={modalRender}>
                    <div className="modal-content">
                        <div className="modal-header">
                            <h4 className="modal-title" id="contact-modalLabel">Contact Us</h4>
                            <button type="button" className="close" data-dismiss="modal" aria-hidden="true" onClick={() => window.$('#contact-modal').modal('hide')}>×</button>
                        </div>
                        <div className="modal-body">
                            {statusError === "OK" ?
                                <>
                                    <p className="text-center pt-3">
                                        <i className="fas fa-envelope-open-text text-success" style={{ fontSize: "2.5em" }}></i>
                                    </p>
                                    <p className="text-center font-weight-bold">Success!</p>
                                    <p className="text-center font-weight-bold">
                                        Your email has been sent. Thanks!
                                    </p>
                                    <p className="text-center">
                                        <button className="btn btn-primary btn-block" type="button" onClick={() => window.$('#contact-modal').modal('hide')}> Close </button>
                                    </p>
                                </>
                                :
                                <form action="#" onSubmit={this.handleSubmitForm}>
                                    <p className="signup-margin" style={{color:'silver'}}>
                                        Have issues or ideas on how we can make this site better? We'd love to hear from you!
                                    </p>
                                    <div className="row signup-margin">
                                        <div className="col-md-6">
                                            {
                                                member ?
                                                <input className="form-control" type="text" placeholder="Name" required readonly="" value={this.state.name} onChange={(e) => this.setState({ name: e.target.value })} />
                                                :
                                                <input className="form-control" type="text" placeholder="Name" required value={this.state.name} onChange={(e) => this.setState({ name: e.target.value })} />
                                            }
                                            
                                        </div>
                                        <div className="col-md-6">
                                            {
                                                member ?
                                                <input className="form-control" type="email" id="emailaddress" required readonly="" placeholder="Email address" value={this.state.email} onChange={(e) => this.setState({ email: e.target.value })} />
                                                :
                                                <input className="form-control" type="email" id="emailaddress" required placeholder="Email address" value={this.state.email} onChange={(e) => this.setState({ email: e.target.value })} />
                                            }
                                            
                                        </div>
                                    </div>
                                    <div className="row signup-margin mt-3">
                                        <div className="signup-email">
                                            <textarea className="form-control" rows={5} required placeholder="How can we help you" value={this.state.message} onChange={(e) => this.setState({ message: e.target.value })} />
                                        </div>
                                    </div>
                                    <div className="row signup-margin mt-3">
                                        <div className="col-md-12">
                                            {statusError != '' &&
                                                <div className="alert alert-danger fade show" role="alert">
                                                    {statusError}
                                                </div>
                                            }
                                        </div>
                                    </div>

                                    <div className="row signup-margin mt-3">
                                        <div className="col-md-12" style={{ paddingLeft: 0 }}>
                                            <button className="btn btn-primary btn-block" type="submit"> Send </button>
                                        </div>
                                    </div>
                                    <br /><br /><br />
                                </form>
                            }
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Contact;