import React, {Fragment} from 'react';


class Privacy extends React.Component {
 
    render(){
        var html = this.props.html;
        var title = '';
        let applicationSettingList = this.props.applicationSettingList;
        if (applicationSettingList) {
            applicationSettingList.map(async function (row) {
                if (row.setting_type === "TEXT_PRIVACY") {
                    html = row.value1;
                    title = row.value2;
                }
            });
        }
        return(
            <div id={this.props.id} className="modal fade" tabIndex="-1" role="dialog"
                aria-labelledby="privacy-modalLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                        <h4 className="modal-title" id="about-modalLabel">{title}</h4>
                            <button type="button" className="close" data-dismiss="modal" aria-hidden="true" onClick={() => window.$('#detail-modal').modal('hide')}>×</button>
                        </div>
                        <div className="modal-body">
                            <div dangerouslySetInnerHTML={{__html: html}} />
                            <br />
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Privacy;