import React, {Component} from 'react';
import {Route, Switch} from 'react-router-dom';

import Home from './pages/Home';
import UpdateLatLng from './pages/UpdateLatLng';

class App extends Component {
  
  render(){
    const App = () => (
      <div>
          <Switch>
              <Route path='/updatelatlng' component={UpdateLatLng} />
              <Route path='/' component={Home} />
              <Route path='/auction/:id' component={Home} />
              <Route path='/join' component={Home} />
              <Route path='/join_email' component={Home} />
              <Route path='/login_email' component={Home} />
              
          </Switch>
      </div>
    )
    return (
      <Switch>
         <App />
      </Switch>
    );
  }
}


export default App;