import React from 'react';


class NoAccess extends React.Component {
 
    render(){
        return(
            <div id="noaccess-modal" className="modal fade" tabIndex="-1" role="dialog"
                aria-labelledby="noaccess-modalLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-body">
                            <p className="text-center pt-4 pl-5 pr-5">
                                This site is currently under development and specific users have access
                                to this data.
                            </p>
                            <p className="text-center pt-3 pl-5 pr-5">
                                If you belive you should have access, please check back later.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default NoAccess;