import React from 'react';
import axios from 'axios';
import {Redirect} from 'react-router-dom';
import './Home.css'

import Header from './components/Header'
import Map from './components/Map'
import AuctionList from './components/AuctionList'
import SignUpModal from './components/SignUp'
import LoginModal from './components/Login'
import SocialLoginModal from './components/SocialLogin'
import ReferModal from './components/Refer'
import DetailModal from './components/Detail'
import ContactModal from './components/Contact'
import MessageModal from './components/MessageModal'
import AboutUsModal from './components/AboutUsModal'
import FaqModal from './components/FaqModal'
import PropertyDetail from './components/PropertyDetail'
import Term from './components/Term'
import Privacy from './components/Privacy'
import NoAccess from './components/NoAccess'
import AccessCredit from './components/AccessCredit'

import * as Utility from '../utilities/Utility'
import * as GlobalFunction from '../utilities/GlobalFunction'
import Pagination from "react-js-pagination";

import {Helmet} from "react-helmet";

class Home extends React.Component {

  constructor(props){
    super();
    this.state = {
      mapHeight: 420,
      listHeight: 420,
      googleMapsLoaded: false,
      auctionUpcoming: [],
      auctionPast: [],
      auctionMember: [],
      auctionItemList: [],
      activePage: 1,
      itemsPerPage: 3000,
      redirectToPage: '',
      member: null,
      selectedAuction: null,
      selectedAuctionId: 0,
      selectedAuctionIsUpcoming: false,
      selectedAuctionItemId: 0,
      notLoggedInRows: 10,
      pageTitle: 'County Tax Sales - Deed and Lien Auctions',
      pageDescription: 'CountyTaxSales lists all county real estate deed, lien, surplus, and sheriff auctions',

      isHideSection: false, //to hide one section
      MapOrList: 'Map',
      applicationSettingList: null,
     
      orderByValue: "2",
      parcelIdValue: "",
      zipValue: "",
      bid1Value: 0,
      bid2Value: 0,
      imp1Value: 0,
      imp2Value: 0,
      land1Value: 0,
      land2Value: 0,
      isLot: false,
      isImp: false
    }
    this.mapEventHandler = React.createRef();
    this.loginEventHandler = React.createRef();
    this.signUpEventHandler = React.createRef();
    this.referEventHandler = React.createRef();
    this.auctionEventHandler = React.createRef();
    this.contactUsEventHandler = React.createRef();
    this.messageModalEventHandler = React.createRef();
}
  
  componentDidMount() {
    this.onScriptload();
    let member = GlobalFunction.getMember();
    if (member) {
      this.setState({ member: member });
      this.fillActionData(member.member_id);
    }
    else{
      this.fillActionData(null);
    }    
    this.resize();    
  }

  onScriptload=()=>{
    console.log("onScriptload");
    const self=this;

    let sApp = document.createElement('script')
    sApp.src="/assets/js/app.js"
    document.body.appendChild(sApp)
    
    let s = document.createElement('script')
        s.src="https://maps.googleapis.com/maps/api/js?libraries=places,geometry&key=" + Utility.googleKey() + "&v=3"
        document.body.appendChild(s)
        s.addEventListener('load',function() {
            self.setState(state=>({
              googleMapsLoaded:!state.googleMapsLoaded
            }))
        })
  }

  fillActionData = (memberId) => {
    axios.get(Utility.getServerUrl() + '/getAuction?member_id=' + memberId)
    .then(res => {
      const auctionUpcoming = res.data.auctionUpcoming;
      const auctionPast = res.data.auctionPast;
      const applicationSettingList= res.data.applicationList;
      const auctionMember = res.data.auctionMember;
      
      this.setState({ auctionUpcoming, auctionPast, auctionMember, applicationSettingList });
       
      let url = window.location.pathname;
      if(url.indexOf("auction") !== -1){
        this.actionDataFilter(url.replace("/auction/","", null));
        //this.startTour(); 
      }
      else if(url.indexOf("join_email") !== -1){
        this.handleSignUpClick();
      }
      else if(url.indexOf("join") !== -1){
        this.handlerSocialModal();
      }
      else if(url.indexOf("login_email") !== -1){
        this.handleLoginClick();
      }
      else if(url.indexOf("propertydetail") !== -1){
        //property detail url
        let arr = url.split('/');
        this.getActionItemById(Number(arr[3]));
      }
      else if(url.indexOf("terms") !== -1){
        window.$('#term_social').modal('show');
      }
      else if(url.indexOf("privacy-policy") !== -1){
        window.$('#privacy_social').modal('show');
      }
      else if(url.indexOf("faq") !== -1){
        window.$('#faq-modal').modal('show');
      }
      else if(url.indexOf("about-us") !== -1){
        window.$('#about-modal').modal('show');
      }
      else{
        this.setDefaultselectedAuction();
      }

    })
  }

  actionDataFilter = (url, selectedActionItemId) => {
    // document.title = title.replace("/","-", null)
    const {auctionUpcoming, auctionPast} = this.state;
    auctionUpcoming.map((row, index) => {
      if(row.url === url){
        let selectedAuction = this.setselectedAuction(row);
        this.handleStandardModal('hide', '')
        this.setState({ selectedAuctionId: row.auction_id, 
                        selectedAuction: selectedAuction, 
                        selectedAuctionIsUpcoming: true })
        this.fillData(null, true, selectedActionItemId);
        
        let title = this.formatTitle(row.url)
        this.setState({pageTitle: title})
        this.setState({pageDescription: 'Auction List for ' + title + '.' + '  ' + row.auction_item_cnt + ' properties for auction' })
    
      }
    })

    auctionPast.map((row, index) => {
      if(row.url === url){
        this.handleStandardModal('hide', '')
        let selectedAuction = this.setselectedAuction(row);
        this.setState({selectedAuctionId: row.auction_id, 
                       selectedAuction: selectedAuction,
                       selectedAuctionIsUpcoming: false
                      })
        this.fillData(null, true, selectedActionItemId);
        
        let title = this.formatTitle(row.url)
        this.setState({pageTitle: title})
        this.setState({pageDescription: title + '.' + row.auction_item_cnt + '  properties for auction' })

      }
    })
  
  
   
  
  }

  setDefaultselectedAuction = () =>{
    const {auctionUpcoming, auctionPast} = this.state;
    this.setState({selectedAuctionId: 5}, ()=>{
      auctionUpcoming.map((row, index) => {
        if(row.auction_id === this.state.selectedAuctionId){
          let selectedAuction = this.setselectedAuction(row);
          this.handleStandardModal('show', '');
          this.setState({ selectedAuction: selectedAuction, 
                          selectedAuctionIsUpcoming: true })
          this.fillData(null, true, null);
        }
      })
  
      auctionPast.map((row, index) => {
        if(row.auction_id === this.state.selectedAuctionId){
          this.handleStandardModal('show', '')
          let selectedAuction = this.setselectedAuction(row);
          this.setState({selectedAuction: selectedAuction,
                         selectedAuctionIsUpcoming: false
                        })
          this.fillData(null, true, null);
        }
      })
            
    
    })

   }

  setselectedAuction = (row) => {
    let selectedAuction = {
      name: row.name, 
      state: row.state, 
      url: row.url,
      extra_display_field: row.extra_display_field,
      dt_of_auction: row.dt_of_auction,
      type: row.type,
      auction_member_id: row.auction_member_id
    }
    return selectedAuction;
  }

  getActionItemById = (selectedActionItemId) => {
    axios.get(Utility.getServerUrl() + '/getUrlByActionItemId?auction_item_id=' + selectedActionItemId)
    .then(res => {
        //console.log(".....:" + res.data)
        this.actionDataFilter(res.data, selectedActionItemId);
    })
  }

  fillData = (paras, isLoad, selectedActionItemId) => {
    let fixParas = '';
    fixParas = "auctionid=" + this.state.selectedAuctionId;
    if (paras)
      fixParas = fixParas + paras
    axios.get(Utility.getServerUrl() + '/getAuctionItemList?' + fixParas)
    .then(res => {
      const auctionItemList = res.data;
      this.setState({ auctionItemList }, ()=>{
        this.showPropertyDetailModal(selectedActionItemId);
      });
      
      if(!isLoad){
        this.mapEventHandler.current.handlePageChange(this.state.auctionItemList);
      }
      
    })
  }
  
  resize = () => {
    setTimeout(()=> this.resizeAll(), 1000)
  };

  resizeAll = () => {
    console.log("resizeAll")
      var width = window.innerWidth;
      var height = window.innerHeight;
      //console.log("height:" + height)
      
      //if(this.topNavBarDiv){
        // const topNavBarDiv = this.topNavBarDiv.clientHeight;
        // const topNavBarDiv2 = this.topNavBarDiv2.clientHeight;
        // const topNavBarDiv3 = this.topNavBarDiv3.clientHeight;
        
        const topNavBarDiv = document.getElementById('topNavBarDiv').clientHeight;
        const topNavBarDiv2 = document.getElementById('topNavBarDiv2').clientHeight;
        const topNavBarDiv3 = this.topNavBarDiv3.clientHeight;
    
        if (width < 767){
          this.setState({
            mapHeight: height - topNavBarDiv - topNavBarDiv2 - topNavBarDiv3,
            listHeight: height -topNavBarDiv - topNavBarDiv2 - topNavBarDiv3,
            isHideSection: true
          });
        }
        else {
          this.setState({
            mapHeight: height - topNavBarDiv - topNavBarDiv2-10,
            listHeight: height -topNavBarDiv - topNavBarDiv2 - topNavBarDiv3,
            isHideSection: false
          });
        }
     // }  
    
  }
  
  handleRowMouseOver = (index) => {
    if(this.mapEventHandler.current){
      this.mapEventHandler.current.handleRowMouseOver(index);
    }
    
  }

  handleRowMouseOut = (index) => {
    if(this.mapEventHandler.current){
      this.mapEventHandler.current.handleRowMouseOut(index);
    }
    
  }

  mouseOverMarkerHandler = (aId) => {
    //move to that row
     const row = document.getElementById("row_" + aId);
     if(row) {
      row.style.backgroundColor = '#f3f7f9'
     }
     
  }

  mouseOutMarkerHandler = (aId) => {
    const row = document.getElementById("row_" + aId);
    if(row) {
      row.style.backgroundColor = ''
    }
  }

  handlePageChange(pageNumber) {
    console.log(`active page is ${pageNumber}`);
    this.setState({activePage: pageNumber});
    
    if(this.mapEventHandler.current){
      this.mapEventHandler.current.handlePageChange(this.getPageRecords(pageNumber));
    }
  }

  getPageRecords = (pageNumber) => {
    const { auctionItemList, itemsPerPage } = this.state;

    const indexOfLastTodo = pageNumber * itemsPerPage;
    const indexOfFirstTodo = indexOfLastTodo - itemsPerPage;
    const list = auctionItemList.slice(indexOfFirstTodo, indexOfLastTodo);
    return list;
  }

  handleFormSubmit = (e) => {
    e.preventDefault();
    const {orderByValue, parcelIdValue, zipValue, bid1Value, bid2Value, imp1Value, imp2Value, 
      land1Value, land2Value, isImp, isLot} = this.state;


      let qryParas = '';
      qryParas = "&orderBy=" + orderByValue;

      if(parcelIdValue){
        qryParas = qryParas + "&parcel_id=" + parcelIdValue;
      }
      if(zipValue){
        qryParas = qryParas + "&zipCodes=" + zipValue;
      }
  
      qryParas = qryParas + "&bidStartAmount=" + bid1Value + "&bidEndingAmount=" + bid2Value;
      //qryParas = qryParas + "&AssetImpStarting=" + imp1Value + "&AssetImpEnding=" + imp2Value;
      // qryParas = qryParas + "&assetLandStarting=" + land1Value + "&assetLandEnding=" + land2Value;
      // qryParas = qryParas + "&isImp=" + isImp
      // qryParas = qryParas + "&isLot=" + isLot
      
      this.fillData(qryParas, null, null);
      window.$.RightBar.toggleRightSideBar();
  }

  handleFormClear = (e) => {
    e.preventDefault();
    this.setState({
      orderByValue: "2",
      parcelIdValue: "",
      zipValue: "",
      bid1Value: 0,
      bid2Value: 0,
      imp1Value: 0,
      imp2Value: 0,
      land1Value: 0,
      land2Value: 0,
      isLot: false,
      isImp: false
    })
    //document.getElementById("form-filter").reset();
    this.form.reset()
    this.fillData();
  }

  handleStandardModal = (status, param) => {
    if(this.auctionEventHandler.current){
      this.auctionEventHandler.current.init(param);
      window.$('#standard-modal').modal(status);
    }
    else{
      console.log("handleStandardModal: null")
    }
  }
  
  
  // handleSignUpModal = (isRegularSignup) => {
  //   if(isRegularSignup){
  //     this.signUpEventHandler.current.reset(false);
  //     this.props.history.push('/join');
  //     //window.$('#signUp-modal').modal('show');
  //     window.$('#social-modal').modal('show');
  //   }
  //   else{
  //     //process join rid url
  //     //if member is already logged-in then should not show modal
  //     if(!this.state.member){
  //       this.signUpEventHandler.current.reset(true);
  //       //window.$('#signUp-modal').modal('show');
  //       window.$('#social-modal').modal('show');
  //     }
  //   }
  // }

  handlerSocialModal = () => {
    this.props.history.push('/join');
    window.$('#standard-modal').modal('hide');
    window.$('#login-modal').modal('hide');
    window.$('#social-modal').modal('hide');
    window.$('#signUp-modal').modal('hide');
    window.$('#social-modal').modal('show');
  }

  handleSignUpClick = () => {
    this.signUpEventHandler.current.reset();
    window.$('#login-modal').modal('hide');
    window.$('#social-modal').modal('hide');
    window.$('#signUp-modal').modal('show');

    let url = window.location.href.toString();
    if(url.indexOf("join_email")<0)
    this.props.history.push('/join_email');
  }
  
  handleLoginClick = () => {
    this.loginEventHandler.current.resetLogin();
    window.$('#social-modal').modal('hide');
    window.$('#signUp-modal').modal('hide');
    window.$('#login-modal').modal('show');
    this.props.history.push('/login_email');
  }

  

  handleModalRowClick = (row, is_dt_of_data_load, dt_of_data_load, is_auction_item_cnt, actionType) => {
   //actionType = upcoming

    if(is_dt_of_data_load){
      if(!this.state.member){
        this.messageModalEventHandler.current.show('The list for this auction will be published by the county on', dt_of_data_load);
      }
      else{
        this.messageModalEventHandler.current.show();
        this.messageModalEventHandler.current.show('The list for this auction will be published by the county on', dt_of_data_load);
        //toast.info(this.toastRender("The list for this auction will be available on " + dt_of_data_load));
      }
    }
    else if (is_auction_item_cnt){
      if(!this.state.member){
        this.messageModalEventHandler.current.show("The list for this auction is not yet available");
        //toast.info(this.toastRender("The list for this auction is not yet available", "Sign up to get notified"));
      }
      else{
        this.messageModalEventHandler.current.show();
        this.messageModalEventHandler.current.show("The list for this auction is not yet available");
        //toast.info(this.toastRender("The list for this auction is not yet available"));
      }
    }
    else{
      this.handleStandardModal('hide', '');

      let selectedAuctionIsUpcoming = false;
      if(actionType === "upcoming"){
        selectedAuctionIsUpcoming = true
      }

      let selectedAuction = {
        name: row.name, 
        state: row.state, 
        url: row.url,
        extra_display_field: row.extra_display_field,
        dt_of_auction: row.dt_of_auction,
        type: row.type,
        auction_member_id: row.auction_member_id
      }
      this.setState({
        selectedAuctionId: row.auction_id,
        selectedAuction: selectedAuction,
        selectedAuctionIsUpcoming: selectedAuctionIsUpcoming
      }, () => {
        this.fillData();
      });
      this.props.history.push('/auction/' + row.url);

      //let title = row.url;
      //document.title = title.replace("/","-", null)
      let title = this.formatTitle(row.url)
      this.setState({pageTitle: title})
      this.setState({pageDescription: 'Auction List for ' + title + '.' + '  ' + row.auction_item_cnt + ' properties for auction' })

      //this.startTour();    
    }
  }

  formatTitle = (url) => {
    let title = url.replace("/", " ", null)
    title = title.replace(/-/g, ' ')
    return title
  }

  startTour = () => {
    let value = GlobalFunction.getIntroJs();
    if (!value) {
      GlobalFunction.setIntroJs();
      window.introJs().start();
    }
  }
  
  showModal = (modalName) => {
    const {selectedAuction} = this.state;
    let history = this.props.history;
    
    window.$('#' + modalName).modal('show');
    if(modalName == 'contact-modal'){
      this.contactUsEventHandler.current.reset(false);
    }
    else if (modalName == 'property-detail-modal') {
      window.$('#' + modalName).on('hidden.bs.modal', function () {
        if(selectedAuction){
          history.push('/auction/' + selectedAuction.url);
        }
      })
    }
    else if (modalName === 'faq-modal'){
      history.push('/faq');
    }
    else if (modalName === 'about-modal'){
      history.push('/about-us');
    }
  }

  handleReferClick = () => {
    if(this.state.member){
      this.referEventHandler.current.reset();
      window.$('#refer-modal').modal('show');
    }
    else{
      this.handlerSocialModal()
    }
    
  }

  switchSection = () => {
    const {MapOrList} = this.state
    if(MapOrList === 'Map'){
      this.setState({MapOrList: 'List'}, ()=>{
        this.resizeAll();
      })
    }
    else{
      this.setState({MapOrList: 'Map'}, ()=>{
        this.resizeAll();
      })
    }
    
  }

  showPropertyDetailModal = (itemId) => {
    console.log("showPropertyDetailModal........");
    
    const {auctionItemList} = this.state;
    const objItem = auctionItemList.find(item => item.auction_item_id === itemId);
    if(objItem){
      let address = objItem.property_address;
      if(objItem.city){
          address = address + ' ' + objItem.city
      }
      if(objItem.state){
          address = address + ' ' + objItem.state
      }
      if(objItem.zip){
          address = address + ' ' + objItem.zip
      }

      address = address.replace(/[^a-zA-Z0-9 ]/g, "")
      address = address.replace(/\s+/g, '-');
    
      this.setState({selectedAuctionItemId: itemId}, ()=>{
        this.props.history.push('/propertydetail/' + address + '/' + itemId);
        this.showModal('property-detail-modal');
        this.InsertMemberClick(itemId)
      })    
    }
  }

  InsertMemberClick = (actionItemId) => {
    const {member} = this.state;
    if(member){
      axios.post(Utility.getServerUrl() + '/InsertAuctionItemClick', {
        actionItemId: actionItemId,
        member_id: member.member_id
      })
      .then(res => {
          //res.data
       })
    }
  }

  render() {
    const { mapHeight, listHeight, googleMapsLoaded, auctionItemList,
      activePage, itemsPerPage, redirectToPage, member, auctionUpcoming, auctionPast,
      isHideSection, MapOrList, applicationSettingList, 
      selectedAuctionId, selectedAuctionItemId, selectedAuctionIsUpcoming, 
      selectedAuction, notLoggedInRows, auctionMember, pageTitle, pageDescription } = this.state;

    if (redirectToPage !== '') {
      return (
        <Redirect to={{ pathname: redirectToPage }}
        />
      );
    }
    let mapContent;

    // Logic for displaying todos
    const auctionItemPageList = this.getPageRecords(activePage);

    if (!googleMapsLoaded) mapContent = (<div>Loading...</div>)
    else if (googleMapsLoaded) {
      let noAccess = false;
      if(selectedAuctionIsUpcoming && !selectedAuction.auction_member_id){
        noAccess = true
      }

      let isActionMemberFound = false;
      auctionMember.map((srow, sindex) => {
        if (srow.auction_id == selectedAuctionId) {
          isActionMemberFound = true;
        }
      });

      mapContent = (<div>
        <Map googleMapsLoaded={googleMapsLoaded}
          mapHeight={mapHeight}
          auctionList={auctionItemPageList}
          ref={this.mapEventHandler}
          onMouseOverMarker={this.mouseOverMarkerHandler}
          onMouseOutMarker={this.mouseOutMarkerHandler}
          member={member}
          showPropertyDetailModal={this.showPropertyDetailModal}
          noAccess = {noAccess}
          notLoggedInRows = {notLoggedInRows}
          handlerSocialModal = {this.handlerSocialModal}
          selectedAuctionIsUpcoming = {selectedAuctionIsUpcoming}
          isActionMemberFound = {isActionMemberFound}
        />
      </div>
      )
    }

    let pagingHtml;
    if (auctionItemList.length > itemsPerPage){
      pagingHtml = (<Pagination
        itemClass="page-item"
        linkClass="page-link"
        activePage={this.state.activePage}
        itemsCountPerPage={itemsPerPage}
        totalItemsCount={auctionItemList.length}
        pageRangeDisplayed={5}
        onChange={this.handlePageChange.bind(this)}
      />)
    }
    //isHideSection
    let mapClass = 'mLeft';
    let listClass = 'mRight';
    let bothText = '';

    if(isHideSection && MapOrList === 'Map'){
      console.log("map:" + mapHeight)
      mapClass = 'mLeft'
      listClass = 'mRight hideSection'
      bothText = 'List';
    }
    else if(isHideSection && MapOrList === 'List'){
      console.log("list")
      mapClass = 'mLeft hideSection'
      listClass = 'mRight'
      bothText = 'Map';
    }

    return (
      <>
      <Helmet>
        <title>{pageTitle}</title>
        <meta name="description" content={pageDescription} />
      </Helmet>
       <div id="wrapper">
        <Header
          handleStandardModal = {this.handleStandardModal}
          showModal = {this.showModal}
          handleReferClick = {this.handleReferClick}
          handlerSocialModal = {this.handlerSocialModal}
        />  
          <div className="content-page" style={{ height: "100%", paddingBottom: 0 }}>
            {
              isHideSection &&
              this.detailMenu(bothText)
            }
            <div className="row" style={{ height: "100%", paddingBottom: 0 }}>
              <div className={mapClass}>
                {mapContent}
              </div>
              <div className={listClass}>

                {
                  !isHideSection &&
                  this.detailMenu(bothText)
                }

                <div className="table-responsive" style={{ height: listHeight, backgroundColor: 'white' }}>
                  <table id={"mytable"} cellPadding={0} cellSpacing={0} 
                      className="mytable table table-hover table-centered mb-0">
                    {this.tableRender(auctionItemPageList)} 
                  </table>
                  {pagingHtml}
                </div>
              </div>
            </div>
          </div>

          {this.sidebarRender()}
          <div className="rightbar-overlay"></div>
        </div>
       
        
        <AuctionList
          ref={this.auctionEventHandler}
          auctionUpcoming={auctionUpcoming}
          auctionPast={auctionPast}
          applicationSettingList={applicationSettingList}
          onModalRowClick={this.handleModalRowClick}
          handlerSocialModal={this.handlerSocialModal}
        />
        <SignUpModal
          ref={this.signUpEventHandler}
          onLoginClick={this.handleLoginClick}
          onChangeUrl={this.handleChangeUrl}
          handlerSocialModal = {this.handlerSocialModal}
         />
        <SocialLoginModal onChangeUrl={this.handleChangeUrl}
          handleLoginClick={this.handleLoginClick}
          handleSignUpClick={this.handleSignUpClick}/>
        <LoginModal 
          ref={this.loginEventHandler}
          onSignUpClick={this.handleSignUpClick}
          handlerSocialModal = {this.handlerSocialModal}
        />
        <ReferModal ref={this.referEventHandler} />
        <DetailModal 
          selectedAuctionId={selectedAuctionId} 
          auctionUpcoming={auctionUpcoming} 
          auctionPast={auctionPast}
          totalProperties={auctionItemList.length} />
        <ContactModal ref={this.contactUsEventHandler} />
        <MessageModal ref={this.messageModalEventHandler} />
        <AboutUsModal applicationSettingList={applicationSettingList} />
        
        <Term applicationSettingList={applicationSettingList} id={"term_social"} />
        <Term applicationSettingList={applicationSettingList} id={"term_signup"} />

        <Privacy applicationSettingList={applicationSettingList} id={"privacy_social"} />
        <Privacy applicationSettingList={applicationSettingList} id={"privacy_signup"} />

        <FaqModal applicationSettingList={applicationSettingList} />
        <NoAccess />
        <PropertyDetail 
          selectedAuctionItemId = {selectedAuctionItemId} 
          auctionItemList = {auctionItemList}
        />
        <AccessCredit selectedAuctionId={selectedAuctionId} 
          auctionUpcoming={auctionUpcoming} 
          auctionPast={auctionPast}
          totalProperties={auctionItemList.length}
          />
      </> 
    );

  }

  sidebarRender(){
    const { member } = this.state;
    if (member) {
      return (
        <div className="right-bar">
          <form id="form-filter" ref={form => this.form = form}>
            <h5 className="font-weight-medium px-3 m-0 py-2 font-13 text-uppercase bg-light">
              <span className="d-block py-1">FILTER LIST</span>
            </h5>
            <div className="rightBarRow">
              <h6>Order By</h6>
            </div>
            <div className="rightBarRow">
              <select className="form-control" value={this.state.orderByValue}
                onChange={(e) => this.setState({ orderByValue: e.target.value })}>
                {/* <option value="0">Property Address</option> */}
                <option value="1">Min Bid</option>
                <option value="2">Zip</option>
                {/* <option value="3">Lot Or Imp</option>
                <option value="4">Parcel Id</option>
                <option value="5">Zillow Price Est</option>
                <option value="6">Trulia Crime Rating</option>
                <option value="7">Ass Land Value</option>
                <option value="8">Ass Imp Value</option> */}
              </select>
            </div>
            <div className="rightBarRow">
              <h6>Parcel Id</h6>
            </div>
            <div className="rightBarRow">
              <input type="text" className="form-control"
                onChange={(e) => this.setState({ parcelIdValue: e.target.value })} />
            </div>

            <div className="rightBarRow">
              <h6>Zip Codes</h6>
            </div>
            <div className="rightBarRow">
              <input type="text" className="form-control" placeholder="example: 46201,11710"
                onChange={(e) => this.setState({ zipValue: e.target.value })} />
            </div>

            <div className="rightBarRow">
              <h6>Bid Amount</h6>
            </div>
            <div className="rightBarRow row">
              <div className="col-md-6">
                <input type="text" placeholder="from" data-a-sign="$ " data-v-min="0" data-v-max="999999999" className="form-control autonumber"
                  onKeyUp={(e) => this.setState({ bid1Value: e.target.value.replace('$ ', '') })} />
              </div>
              <div className="col-md-6">
                <input type="text" placeholder="to" data-a-sign="$ " data-v-min="0" data-v-max="999999999" className="form-control autonumber"
                  onKeyUp={(e) => this.setState({ bid2Value: e.target.value.replace('$ ', '') })} />
              </div>
            </div>

            {/* <div className="rightBarRow">
              <h6>Type</h6>
            </div>

            <div className="rightBarRow" style={{ paddingTop: "10px" }}>
              <table>
                <tbody>
                  <tr>
                    <td>
                      <div className="custom-control custom-checkbox">
                        <input type="checkbox" className="custom-control-input" id="checkmeout0"
                          onClick={() => this.setState({ isLot: !this.state.isLot })} />
                        <label className="custom-control-label" htmlFor="checkmeout0">Lot</label>
                      </div>
                    </td>
                    <td style={{ paddingLeft: "10px" }}>
                      <div className="custom-control custom-checkbox">
                        <input type="checkbox" className="custom-control-input" id="checkmeout1"
                          onClick={() => this.setState({ isImp: !this.state.isImp })} />
                        <label className="custom-control-label" htmlFor="checkmeout1">Improvement</label>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div> 

            <div className="rightBarRow">
              <h6>Asset Improvement Value</h6>
            </div>
            <div className="rightBarRow row">
              <div className="col-md-6">
                <input type="text" placeholder="from" data-a-sign="$ " data-v-min="0" data-v-max="999999999" className="form-control autonumber"
                  onKeyUp={(e) => this.setState({ imp1Value: e.target.value.replace('$ ', '') })} />
              </div>
              <div className="col-md-6">
                <input type="text" placeholder="to" data-a-sign="$ " data-v-min="0" data-v-max="999999999" className="form-control autonumber"
                  onKeyUp={(e) => this.setState({ imp2Value: e.target.value.replace('$ ', '') })} />
              </div>
            </div>

            <div className="rightBarRow">
              <h6>Asset Land Value</h6>
            </div>
            <div className="rightBarRow row">
              <div className="col-md-6">
                <input type="text" placeholder="from" data-a-sign="$ " data-v-min="0" data-v-max="999999999" className="form-control autonumber"
                  onKeyUp={(e) => this.setState({ land1Value: e.target.value.replace('$ ', '') })} />
              </div>
              <div className="col-md-6">
                <input type="text" placeholder="to" data-a-sign="$ " data-v-min="0" data-v-max="999999999" className="form-control autonumber"
                  onKeyUp={(e) => this.setState({ land2Value: e.target.value.replace('$ ', '') })} />
              </div>
            </div>*/}

            <div style={{ paddingLeft: "5px", paddingRight: "5px" }}>
              <button className="btn btn-primary btn-block mt-4" onClick={this.handleFormSubmit}>APPLY FILTERS</button>
              <button className="btn btn-light btn-block mt-2" onClick={this.handleFormClear}>CLEAR FILTERS</button>
            </div>
          </form>
        </div>
      )

    }
    else {
      return (
        <div className="right-bar">
          <div style={{ textAlign: "center", height: "100%", paddingTop: "50px" }}>
            <a href="#" onClick={() => this.handlerSocialModal()}>Log in to see details</a>
          </div>
        </div>
      )
    }
  }

  tableRender(currentAuctionItemList){
    const { member, selectedAuction, notLoggedInRows, auctionMember, 
      selectedAuctionId, selectedAuctionIsUpcoming } = this.state;
        
    let selectedExtraDisplayField = '';
    if(selectedAuction){
      selectedExtraDisplayField = selectedAuction.extra_display_field;
    }

    let dynmaicValueHeader;
    if (selectedExtraDisplayField === "lot_or_imp") {
      dynmaicValueHeader = "Lot or Imp";
    }
    else if (selectedExtraDisplayField === "ass_total_value") {
      dynmaicValueHeader = "Assess Val";
    }

    let thead = <thead>
      <tr>
        <th>Min Bid</th>
        {
          selectedExtraDisplayField ?
            <th>{dynmaicValueHeader}</th>
            :
            <></>
        }
        <th>Property Address</th>
        <th>Zip</th>
      </tr>
    </thead>
    
    let isActionMemberFound = false;
    auctionMember.map((srow, sindex) => {
      if (srow.auction_id == selectedAuctionId) {
        isActionMemberFound = true;
      }
    });
    
    
    return (
      <>
        {thead}
        <tbody>
          {currentAuctionItemList.map((row, index) => {
            let dynmaicValue = '';
            if (selectedExtraDisplayField === "lot_or_imp") {
              dynmaicValue = row.lot_or_imp;
            }
            else if (selectedExtraDisplayField === "ass_total_value") {
              if (row.ass_total_value > 0) {
                dynmaicValue = GlobalFunction.currencyFormat2(row.ass_total_value);
              }
            }

            if (member) {
              if (isActionMemberFound || !selectedAuctionIsUpcoming) {
                return this.getListRow(row, index, selectedExtraDisplayField, dynmaicValue)
              }
              else{
                if (index < notLoggedInRows) {
                  return this.getListRow(row, index, selectedExtraDisplayField, dynmaicValue)
                }
                else{
                  return <tr id={"row_" + row.auction_id} key={index}
                            onMouseOver={() => this.handleRowMouseOver(index)}
                            onMouseOut={() => this.handleRowMouseOut(index)}>
                            <td>{GlobalFunction.currencyFormat2(row.min_bid)}</td>
                            {selectedExtraDisplayField ?
                              <td colSpan={3}>You currently do not have access. <a className="bottomLink text-primary" onClick={() => this.accessCredit()}>Access this data</a></td>  
                              :
                              <td colSpan={2}>You currently do not have access. <a className="bottomLink text-primary" onClick={() => this.accessCredit()}>Access this data</a></td>
                            }
                         </tr>
                }
              }
            }
            else {
              if (index < notLoggedInRows) {
                return this.getListRow(row, index, selectedExtraDisplayField, dynmaicValue)
              }
              else{
                return <tr id={"row_" + row.auction_id} key={index}
                          onMouseOver={() => this.handleRowMouseOver(index)}
                          onMouseOut={() => this.handleRowMouseOut(index)}>
                          <td>{GlobalFunction.currencyFormat2(row.min_bid)}</td>
                          {selectedExtraDisplayField ?
                            <td colSpan={3}><a href="#" onClick={() => this.handlerSocialModal()}>Log in to see details</a></td>  
                            :
                            <td colSpan={2}><a href="#" onClick={() => this.handlerSocialModal()}>Log in to see details</a></td>
                          }
                       </tr>
              }
            }
          })}
        </tbody>
      </>
    )
    
  }

  getListRow(row, index, selectedExtraDisplayField, dynmaicValue) {
    return <tr id={"row_" + row.auction_item_id} key={index}
      onMouseOver={() => this.handleRowMouseOver(index)}
      onMouseOut={() => this.handleRowMouseOut(index)}
      onClick={() => this.showPropertyDetailModal(row.auction_item_id)}
      style={{ cursor: "pointer" }}
    >
      <td>{GlobalFunction.currencyFormat2(row.min_bid)}</td>
      {selectedExtraDisplayField ?
        <td>
          {dynmaicValue}
        </td>
        :
        <></>
      }
      <td>{row.property_address}</td>
      <td>{row.zip}</td>
    </tr>
  }

  detailMenu(bothText){
    const {isHideSection, selectedAuction} = this.state;

    let selectedType = '';
    let selectedAuctionTitle = '';
    let selected_dt_Of_Auction = '';

    if(selectedAuction){
      selectedAuctionTitle = selectedAuction.name  + ', ' + selectedAuction.state;
      selected_dt_Of_Auction = selectedAuction.dt_of_auction;
      selectedType = selectedAuction.type;
    }
    let badge = "badge lien p-1";
    if (selectedType === "DEED") {
      badge = 'badge deed p-1';
    }
    
    let badgeText = '';
    if(selectedType === 'LIEN'){
      badgeText = 'Indiana Interest Rate: 10% Penalty 1st 6 months, 15% Penalty 2nd 6 months';
    }

    return (
      <div className="row descriptionBar"
        ref={(topNavBarDiv3) => { this.topNavBarDiv3 = topNavBarDiv3 }}>
        <div className="col-md-7" style={{border: '0px solid red'}}>
          <h4 className="header-title pl-2" style={{ color: '#2e75c2', fontWeight: 'bold' }}>
            {selectedAuctionTitle} - <span style={{color: '#999999'}}>{selected_dt_Of_Auction}</span>
            &nbsp;&nbsp;
            <span className={badge} title={badgeText} data-plugin="tippy" data-tippy-animation="shift-away" data-tippy-arrow="true">{selectedType}</span>
          </h4>
        </div>
        <div className="col-md-5" style={{border: '0px solid red'}}>
          <div className='pl-2 ' style={{ float: 'left' }}>
            <button className="btn btn-sm btn-light waves-effect" 
              onClick={() => this.showModal('detail-modal')}  data-intro='Click here to see the details of this auction'>
              <i className="fe-file-text"></i> info
            </button>
            <button className="btn btn-sm btn-light waves-effect ml-1" 
              onClick={() => this.handleStandardModal('show', '')} data-intro='Click here to see the list of other auctions'>
              <i className="fe-tag"></i> auctions
            </button>
          </div>
          <div className='pr-2 ' style={{ float: 'right' }}>
            <table>
              <tbody>
                <tr>
                  <td>
                    <button className="btn btn-sm btn-light waves-effect right-bar-toggle"
                      data-intro='Click here to filter the current auction list'>
                      <i className="fe-sliders"></i> Filter
                    </button>
                  </td>
                  <td>
                    {
                      isHideSection &&
                      <div className="text-right ml-1">
                        <a href="#" onClick={() => this.switchSection()}>{bothText}</a>
                      </div>
                    }
                  </td>
                </tr>
              </tbody>
              
            </table>
          </div>
        </div>
      </div>
    );
  }
  
  noAccess = () => {
    window.$('#noaccess-modal').modal('show');
  }

  accessCredit = () => {
    window.$('#access-credit-modal').modal('show');
  }

  handleChangeUrl = (url) =>{
    this.props.history.push(url);
  }

}

 export default Home;
