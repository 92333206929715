import React from 'react';


class Detail extends React.Component {
  

    render(){
        let selectedAuctionId = this.props.selectedAuctionId;
        let auctionUpcoming = this.props.auctionUpcoming;
        let auctionPast = this.props.auctionPast;
       
        let selectedAuctionTitle;
        let selected_dt_Of_Auction;
        let selectedType;
        let tCount = this.props.totalProperties;
        let selectedExtUrlInfo;
        let selectedDescription;

        auctionUpcoming.map((row, index) => {
            if (row.auction_id === selectedAuctionId) {
                selectedAuctionTitle = row.name + ', ' + row.state;
                selected_dt_Of_Auction = row.dt_of_auction2;
                selectedType = row.type;
                selectedExtUrlInfo = row.ext_url_info;
                selectedDescription = row.description;
            }
        })
        auctionPast.map((row, index) => {
            if (row.auction_id === selectedAuctionId) {
                selectedAuctionTitle = row.name + ', ' + row.state;
                selected_dt_Of_Auction = row.dt_of_auction2;
                selectedType = row.type;
                selectedExtUrlInfo = row.ext_url_info;
                selectedDescription = row.description;
            }
        })

        var html = '';
        if (selectedExtUrlInfo){
            html =  <a className="btn btn-outline-primary waves-effect waves-light" target="_blank" href={selectedExtUrlInfo}>
                        <i className="mdi mdi-latitude mr-1"></i> Go to County Website
                    </a>
        }

        return(
            <div id="detail-modal" className="modal fade" tabIndex="-1" role="dialog"
                aria-labelledby="detail-modalLabel" aria-hidden="true">
                <div className="modal-dialog modal-lg modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-body">
                            <button type="button" className="close" data-dismiss="modal" aria-hidden="true" onClick={() => window.$('#detail-modal').modal('hide')}>×</button>
                            <h3 style={{color:"#2e75c2"}}>{selectedAuctionTitle}</h3><br />
                            
                            <h5>Auction Type: &nbsp;{selectedType}</h5>
                            {
                                (selectedType === 'LIEN') ?
                                  <>
                                      <h5>Interest Rate: 10% Penalty 1st 6 months, 15% Penalty 2nd 6 months</h5>
                                  </>
                                  :
                                  <span></span>
                            }
                            <h5>Auction Date: {selected_dt_Of_Auction}</h5>                            
                            <h5># of Properties: {tCount}</h5>
                            {html}
                            <br /><br /><br />
                            {selectedDescription &&
                                <div dangerouslySetInnerHTML={{ __html: selectedDescription }} />
                            }
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Detail;