import React from 'react';
import axios from 'axios';
import * as Utility from '../../utilities/Utility'
import * as GlobalFunction from '../../utilities/GlobalFunction'
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css

class AccessCredit extends React.Component {
    constructor(props) {
        super()
        this.state = {
            status: "",
            credit_balance: 0,
        }
    }

    componentDidMount() {
        let member = GlobalFunction.getMember();
        if (member) {
            this.fillMemberData(member.member_id)
        }
        //this.gotCreditHandler()
    }


    fillMemberData = async (member_id) => {
        axios.get(Utility.getServerUrl() + '/getMemberData?member_id=' + member_id)
            .then(res => {
                if (res.data.member) {
                    const credit_balance = res.data.member.credit_balance
                    if (credit_balance) {
                        this.setState({ credit_balance });
                    }
                }
            })
    }

    render() {
        const { status, credit_balance } = this.state;
        let selectedAuctionId = this.props.selectedAuctionId;
        let auctionUpcoming = this.props.auctionUpcoming;
        let auctionPast = this.props.auctionPast;

        let selectedAuctionTitle;
        let selected_dt_Of_Auction;
        let selectedType;
        let cost = 0;
        let tCount = this.props.totalProperties;
        auctionUpcoming.map((row, index) => {
            if (row.auction_id === selectedAuctionId) {
                selectedAuctionTitle = row.name + ', ' + row.state;
                selected_dt_Of_Auction = row.dt_of_auction2;
                selectedType = row.type;
                cost = row.cost;
            }
        })
        auctionPast.map((row, index) => {
            if (row.auction_id === selectedAuctionId) {
                selectedAuctionTitle = row.name + ', ' + row.state;
                selected_dt_Of_Auction = row.dt_of_auction2;
                selectedType = row.type;
                cost = row.cost;
            }
        })

        return (
            <div id="access-credit-modal" className="modal fade" tabIndex="-1" role="dialog"
                aria-labelledby="access-credit-modalLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-body">
                            <button type="button" className="close" data-dismiss="modal" aria-hidden="true" onClick={() => window.$('#detail-modal').modal('hide')}>×</button>
                            <h3 style={{ color: "#2e75c2" }}>{selectedAuctionTitle}</h3><br />

                            <div className="row">
                                <div className="col-md-3">Auction Type:</div>
                                <div className="col-md-9">{selectedType}</div>
                            </div>
                            <div className="row">
                                <div className="col-md-3">Auction Date:</div>
                                <div className="col-md-9">{selected_dt_Of_Auction}</div>
                            </div>
                            <div className="row">
                                <div className="col-md-3"># of Properties:</div>
                                <div className="col-md-9">{tCount}</div>
                            </div>
                            <br />

                            {
                                status == "" ?
                                    <>
                                        {
                                            credit_balance >= cost ?
                                                <a className="btn btn-outline-primary waves-effect waves-light text-primary" onClick={() => this.useCreditHandler(cost, selectedAuctionId)}>
                                                    <i className="mdi mdi-database mr-1"></i> Use ${cost} Credit to Access
                                                </a>
                                                :
                                                <>
                                                    <a className="btn btn-outline-primary disabled" style={{ color: "gray", borderColor: "gray" }}>
                                                        <i className="mdi mdi-database mr-1"></i> Use ${cost} Credit to Access
                                                    </a>
                                                    <p className="text-danger pt-2">You do not have enough credits</p>
                                                    <a className="btn btn-outline-primary waves-effect waves-light text-primary">
                                                        <i className="mdi mdi-database mr-1"></i> Add Credits
                                                    </a>
                                                </>
                                        }
                                    </>
                                    :
                                    status == "ok" ?
                                        <>
                                            <p style={{ color: "red" }}>You now have access. please refresh the page</p>
                                        </>
                                        :
                                        <>
                                            <p style={{ color: "red" }}>System Error</p>
                                        </>
                            }

                            <br /><br /><br />
                        </div>
                    </div>
                </div>
            </div>
        )

    }


    useCreditHandler = (cost, selectedAuctionId) => {
        let message = 'Are you sure you want to use $' + cost + ' credits'

        confirmAlert({
            title: 'Confirm to submit',
            message: message,
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => this.useCreditConfirmHandler(cost, selectedAuctionId)
                },
                {
                    label: 'No',
                    onClick: () => { }//alert('Click No')
                }
            ]
        });
    }

    gotCreditHandler = () => {
        let message = 'You now have access.'

        confirmAlert({
            title: 'Success',
            message: message,
            buttons: [
                {
                    label: 'Ok',
                    onClick: () => this.refreshPage()
                }
            ]
        });
    }

    refreshPage = () =>{
        window.location.href = window.location.href;
    }

    useCreditConfirmHandler = (cost, selectedAuctionId) => {
        let member = GlobalFunction.getMember();
        if (member) {
            axios.post(Utility.getServerUrl() + '/insertNewTransaction', {
                auctionId: selectedAuctionId,
                member_id: member.member_id,
                amount: cost * -1,
                details_short: 'BUY_AUCTION_INFO'
            })
                .then(res => {
                    let result = res.data;
                    if (result) {
                        GlobalFunction.setMemberID(result);
                        this.gotCreditHandler();
                        // this.setState({
                        //     status: "ok"
                        // })
                    }
                    else {
                        console.log("error")
                        this.setState({
                            status: "error"
                        })
                    }
                })

        }

    }
}

export default AccessCredit;