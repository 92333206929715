import React from 'react'
import axios from 'axios';
import * as Utility from '../../utilities/Utility'
import * as GlobalFunction from '../../utilities/GlobalFunction'

//user can signup as normal or through referral
//normal signup will insert into member and will send join email
//if registered user try to login it will send an email with guid same like after signup
//it will verify by guid and will insert into transaction / notif tables if status UNVERIFIED

//normal user signup
//refer open from email
//direct past refer url into browser 
//refer_shortname will require only save     //direct url http://localhost:8084/join/Muhammad_156
                        

class SignUp extends React.Component {    
    constructor(props) {
        super();
        this.state = {
            name: '',
            email: '',
            statusError: '',
            isReferral: false,
            member_Name: '',
            member_id_source: 0,
            referral_id: 0,
            refer_shortname: null
        }
    }

    reset = async () => {
        let params = new URLSearchParams(window.location.search);
        let url = window.location.href;
        let rid = params.get('rid');
        let refer_shortname = window.location.pathname.replace("/join_email/","")
   
        if (rid) {
            axios.get(Utility.getServerUrl() + '/getreferralByUUID?rid=' + rid)
                    .then(res => {
                        if (res.data.referralByUUID) {
                            if (res.data.referralByUUID.referred_first_name) {
                                this.setState({ name: res.data.referralByUUID.referred_first_name })
                            }
                           
                            this.setState({
                                email: res.data.referralByUUID.referred_email,
                                member_id_source: res.data.referralByUUID.member_id_source,
                                referral_id: res.data.referralByUUID.referral_id,
                                isReferral: true,
                                member_Name: res.data.referralByUUID.name
                            })
                        }
                    })
        }
        else if(refer_shortname){              
                  
            //cookie the shortname if user past link into browser
            console.log("refer_shortname:" + refer_shortname)
            await GlobalFunction.setReferShortName(refer_shortname)
            this.setState({ refer_shortname: refer_shortname });
        }
        else {
            this.setState({
                name: '',
                email: '',
                statusError: '',
                isReferral: false,
                member_Name: '',
                member_id_source: 0,
                referral_id: 0,
                refer_shortname: null
            });
            //cookie the shortname if user past link into browser
            //get from cookie and set into state variable
            let short_name = GlobalFunction.getReferShortName()
            if(short_name){
                console.log("short_name:" + short_name)
                this.setState({refer_shortname: short_name})
            }
        }
    }

    handleSubmitForm = (e) => {
        e.preventDefault();
        const {name, email, member_id_source, referral_id, refer_shortname} = this.state;
         

        axios.post(Utility.getServerUrl() + '/signUpUser', {
            name: name,
            email: email,
            member_id_source: member_id_source,
            referral_id: referral_id,
            refer_shortname: refer_shortname
        })
        .then(res => {
            if (res.data.messageType === "ERROR") {
                this.setState({statusError:"Email address is already registered!"})
              }
              else if (res.data.messageType === "DBERROR") {
                this.setState({statusError:"Database error!"})
              }
              else {
                  //login
                  if(refer_shortname){
                    GlobalFunction.clearReferShortName();
                  }
                  this.setState({statusError:"OK"})
              }
        })
   
    }

    render(){
        const {statusError, email, isReferral, member_Name} = this.state;

        let modalRender = "";
        if(statusError === "OK"){
            modalRender = "modal-dialog "
        }
        else{
            modalRender = "modal-dialog modal-lg ";
        }
        return (
            <div id="signUp-modal" className="modal fade" tabIndex="-1" role="dialog"
                aria-labelledby="signUp-modalLabel" aria-hidden="true">
                <div className={modalRender}>
                    <div className="modal-content">
                        <div className="modal-body">
                            <button type="button" className="close" data-dismiss="modal" aria-hidden="true" onClick={()=> window.$('#login-modal').modal('hide')}>×</button>
                            <p className="text-center pt-2">
                                <img src="/assets/images/logo-county-tax-sales-full-spell-dark.png" alt="" height="40" />
                            </p>
                            { statusError === "OK" ?
                                <>
                                    {GlobalFunction.emailDone(this.state.email)}
                                    <p className="text-center">
                                        <button className="btn btn-primary btn-block" type="button" onClick={()=> window.$('#signUp-modal').modal('hide')}> Close </button>
                                    </p>
                                </>
                            :
                                <>
                                    { isReferral &&
                                        <h4 className="text-center pt-2">
                                            {member_Name} invited you to try CountyTaxSales!
                                        </h4>
                                    }
                                    <h5 className="text-center text-muted pt-2">
                                        Create a free account today and you'll receive a $5 credit
                                    </h5>
                                    <br />
                                    <div className="row signup-margin">
                                        <div className="col-md-6 text-center">
                                            <i className="fas fa-home" style={{ fontSize: "2.5em" }}></i>
                                            <p className="text-muted mt-2">Buy government owned properties for fraction of their market prices</p>
                                        </div>
                                        <div className="col-md-6 text-center">
                                            <i className="fas fa-money-bill-alt" style={{ fontSize: "2.5em" }}></i>
                                            <p className="text-muted mt-2">Invest in liens that can yield over 18%</p>
                                        </div>
                                    </div>
                                    <form action="#" onSubmit={this.handleSubmitForm}>
                                        <div className="row signup-margin mt-3">
                                        <input className="form-control" type="text" id="name" placeholder="Name" required value={this.state.name} onChange={(e) => this.setState({ name: e.target.value })} />
                                        </div>
                                        <div className="row signup-margin mt-3">
                                        <input className="form-control" type="email" id="emailaddress" required placeholder="Email address" value={this.state.email} onChange={(e) => this.setState({ email: e.target.value })} />
                                        </div>
                                        <div className="row signup-margin mt-3">
                                            <div className="col-md-6">
                                                <div className="custom-control custom-checkbox">
                                                    <input type="checkbox" className="custom-control-input" required id="checkbox-signup" />
                                                    <label className="custom-control-label termsBlue" htmlFor="checkbox-signup">
                                                        I accept <a className="text-blue bottomLink" onClick={()=>this.handleTermClick()}>Terms and Conditions</a> 
                                                        &nbsp;and <a className="text-blue bottomLink" onClick={()=>this.handlePrivacyClick()}>Privacy Policy</a>
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                        {statusError != '' &&
                                            <div className="row signup-margin mt-3">
                                                <div className="col-md-12">
                                                    
                                                        <div className="alert alert-danger fade show" role="alert">
                                                            {statusError}
                                                        </div>
                                                    
                                                </div>
                                            </div>
                                        }
                                        <div className="row signup-margin mt-3 mb-2">
                                            <div className="col-md-12" style={{ paddingLeft: 0 }}>
                                                <button className="btn btn-primary btn-block" type="submit"> Sign Up </button>
                                            </div>
                                        </div>
                                    </form>
                                    <div className="text-center text-white" style={{ position: "relative", top: 60, marginTop: -30 }}>
                                        <a className="text-white bottomLink" onClick={() => this.props.handlerSocialModal()}>Continue w Google or Facebook</a>
                                        &nbsp;&nbsp;&nbsp;-&nbsp;&nbsp;&nbsp;
                                        <a className="text-white bottomLink" onClick={() => this.props.onLoginClick()}>Log in With Email</a>
                                    </div>
                                </>
                            }
                            
                            
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    handleTermClick = () =>{
        
        this.props.onChangeUrl('/terms')
        window.$('#signUp-modal').modal('hide');
        
        window.$('#term_signup').modal('show');
        window.$('#term_signup').on('hidden.bs.modal', () => {
            window.$('#signUp-modal').modal('show');
            this.props.onChangeUrl('/join_email')
        });
    }

    handlePrivacyClick = () =>{
        this.props.onChangeUrl('/privacy-policy')
        window.$('#signUp-modal').modal('hide');
        
        window.$('#privacy_signup').modal('show');
        window.$('#privacy_signup').on('hidden.bs.modal', () => {
            window.$('#signUp-modal').modal('show');
            this.props.onChangeUrl('/join_email')
        });
    }
}

export default SignUp;