import React from 'react'
import axios from 'axios';
import * as Utility from '../../utilities/Utility'
import * as GlobalFunction from '../../utilities/GlobalFunction'
import {CopyToClipboard} from 'react-copy-to-clipboard'

class Refer extends React.Component {    
    constructor(props) {
        super();
        this.state = {
            email: '',
            copied: false,
            referUrl: '',
            statusError: '',
        }
    }


    reset = () => {
        this.setState({
            email: '',
            copied: false,
            referUrl: '',
            statusError: '',
        });
        this.setReferUrl();        
    }

    setReferUrl = () => {
        let member = GlobalFunction.getMember();
        if (member) {
            let url = Utility.getSiteUrl() + '/join_email/' + member.refer_shortname;
            this.setState({ referUrl: url })
        }
    }

    handleCopyLink = () => {
        const {referUrl} = this.state;

        console.log("handleCopyLink:" + referUrl)

        this.setState({copied: true});
        const timer = setTimeout(() => {
            this.setState({ copied: false });
            clearTimeout(timer);
        }, 3000);
    }

    handleSubmitForm = (e) => {
        e.preventDefault();
        const {email, referUrl} = this.state;
        let member = GlobalFunction.getMember();        
        
        axios.post(Utility.getServerUrl() + '/referral', {
            email: email,
            member_id: member.member_id,
            refer_Url: referUrl
        })
        .then(res => {
            if (res.data.messageType === "ERROR") {
                this.setState({ statusError: res.data.message })
                
            }
            else {
                this.setState({statusError: 'OK', email: ''});
            }
            /////////////////////////////////
            const timer = setTimeout(() => {
                this.setState({ statusError: '' });
                clearTimeout(timer);
            }, 5000);
        })
    }

    render(){
        const {copied, statusError, email} = this.state;
        
        return(
            <div id="refer-modal" className="modal fade" tabIndex="-1" role="dialog"
                aria-labelledby="refer-modalLabel" aria-hidden="true">
                <div className='modal-dialog modal-lg modal-dialog-centered'>
                    <div className="modal-content">
                        <div className="modal-body">
                            <div className="text-right">
                                <button type="button" className="close" data-dismiss="modal" aria-hidden="true"
                                    onClick={() => window.$('#refer-modal').modal('hide')}>×</button>
                            </div>
                            <div className="row mt-3">
                                <div className="col-md-2 text-center">
                                    <img src="/images/gift-box.gif" style={{ height: '70px' }} />
                                </div>
                                <div className="col-md-10 p-0 text-center" style={{ fontFamily: "Verdana" }}>
                                    <h3>Invite a friend to CountyTaxSales and you'll both get $5</h3>
                                </div>
                            </div>
                            <div className="row mt-3">
                                <div className="col-md-8 pl-3">
                                    <form action="#" onSubmit={this.handleSubmitForm}>
                                        <div className="input-group">
                                            <input className="form-control" type="email" value={this.state.email} required placeholder="Email address"
                                                onChange={(e) => this.setState({ email: e.target.value })} />
                                            <div className="input-group-append">
                                                <button className="btn btn-primary waves-effect waves-light" type="submit">Send invites</button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                                <div className="col-md-4 pl-3 referCopy">
                                    <CopyToClipboard text={this.state.referUrl}
                                        onCopy={() => this.setState({ copied: true })}>
                                        <button className="btn btn-white waves-effect waves-light" type="button" onClick={() => this.handleCopyLink()}>
                                            <i className="fas fa-copy"></i> Copy link
                                        </button>
                                    </CopyToClipboard>
                                    {copied && <span className="ml-2">Link copied</span>}
                                </div>
                            </div>
                            <div className="row mt-2">
                                <div className="col-md-12 pl-3">
                                    {statusError !== "OK" ?
                                        <span className="text-danger font-weight-bold">{statusError}</span>
                                     :
                                        <span className="text-success font-weight-bold">
                                            An invite has been sent! Thanks!.
                                        </span>
                                    }
                                </div>
                            </div>
                            <br />
                        </div>
                    </div>
                </div>
            </div>

        )
    }


}    


export default Refer;