import React from 'react';

class MessageModal extends React.Component {
    constructor(props){
        super()
        this.state = {
            message: '',
            date: '',
            notLogged: false
        }
    }

    show = (message, date, notLogged) => {
        this.setState({message, date, notLogged});
        console.log("show");
        window.$('#message-modal').modal('show');
    }

    render(){
        const {message, date, notLogged} = this.state
        return(
            <div id="message-modal" className="modal fade" tabIndex="-1" role="dialog"
                aria-labelledby="message-modalLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content" style={{border:'1px solid gray'}}>
                        <div className="modal-body">
                            <div className="row">
                                <div className="col-md-3 text-center testclass">
                                    <i className="fe-alert-circle" style={{fontSize:'45pt'}}></i>
                                </div>
                                <div className="col-md-9" style={{color:'gray'}}>
                                    {message}<br/>
                                    {date && 
                                        <>
                                            <div className="pt-1 pb-1" style={{fontSize:'14pt'}}><strong>{date}</strong></div>
                                            Please revist us on that date for this auction<br />
                                            Please select another auction
                                        </>
                                    }
                                </div>
                            </div>
                            <br />
                            <p className="text-center">
                                <a href="#" onClick={() => window.$('#message-modal').modal('hide')}>close</a>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default MessageModal;