import React, {Fragment} from 'react';


class AboutUs extends React.Component {
 
    render(){
        var html = '';
        var title = '';
        let applicationSettingList = this.props.applicationSettingList;
        if (applicationSettingList) {
            applicationSettingList.map(async function (row) {
                if (row.setting_type === "TEXT_ABOUT_US") {
                    html = row.value1;
                    title = row.value2;
                }
            });
        }
        return(
            <div id="about-modal" className="modal fade" tabIndex="-1" role="dialog"
                aria-labelledby="about-modalLabel" aria-hidden="true">
                <div className="modal-dialog modal-lg modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h4 className="modal-title" id="about-modalLabel">{title}</h4>
                            <button type="button" className="close" data-dismiss="modal" aria-hidden="true" onClick={() => window.$('#detail-modal').modal('hide')}>×</button>
                        </div>
                        <div className="modal-body">
                            <div dangerouslySetInnerHTML={{__html: html}} />
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default AboutUs;