import React from 'react'
import GoogleLogin from 'react-google-login';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';
import axios from 'axios';
import * as Utility from '../../utilities/Utility'
import * as GlobalFunction from '../../utilities/GlobalFunction'
const publicIp = require('public-ip');

class SocialLogin extends React.Component {
    constructor(props) {
        super();
    }

    handleTermClick = () =>{
        this.props.onChangeUrl('/terms')
        window.$('#social-modal').modal('hide');

        window.$('#term_social').modal('show');
        window.$('#term_social').on('hidden.bs.modal', () => {
            window.$('#social-modal').modal('show');
            this.props.onChangeUrl('/join')
        });
    }

    handlePrivacyClick = () =>{        
        this.props.onChangeUrl('/privacy-policy')
        window.$('#social-modal').modal('hide');
        
        window.$('#privacy_social').modal('show');
        window.$('#privacy_social').on('hidden.bs.modal', () => {
            window.$('#social-modal').modal('show');
            this.props.onChangeUrl('/join')
        });
    }

    responseGoogle = (response) => {
        //console.log(response)
        if(response.profileObj){
            let email = response.profileObj.email;
            let name = response.profileObj.name;            
    
            this.socialSignUp(name, email, 'GOOGLE');
        }
    }

    responseFacebook = (response) => {
        //console.log(response)
        if(response.email){
            let email = response.email;
            let name = response.name;
            
            this.socialSignUp(name, email, 'FB');
        }
    }

    
    socialSignUp = (name, email, auth_source) => {
        axios.post(Utility.getServerUrl() + '/signUpUser', {
            name: name,
            email: email,
            member_id_source: 0,
            referral_id: 0,
            refer_shortname: null,
            isSocialLogin: true,
            auth_source: auth_source
        })
        .then(res => {
            if (res.data.messageType === "ERROR") {
                console.log("Email address is already registered!");
                this.setState({
                    isSocialLogin: true,
                    email: email
                })
                this.submitLogin();
                //this.setState({statusError:"Email address is already registered!"})
                //push to login page
            }
            else if (res.data.messageType === "DBERROR") {
                console.log("Database error!");
                //this.setState({statusError:"Database error!"})
            }
            else {
                if (res.data.guid) {
                    console.log(res.data.guid);
                    this.verifyId(res.data.guid);
                }
            }
        })
   
    }

    submitLogin = () => {
        const {email, isSocialLogin} = this.state;
        axios.post(Utility.getServerUrl() + '/loginUser', {
            email: email,
            isSocialLogin: isSocialLogin
        })
        .then(res => {
            if (res.data.messageType === "ERROR") {
                this.setState({ statusError: res.data.message })
                //set error
            }
            else {
                if(!isSocialLogin)
                    this.setState({isEmailValid: true});
                else{
                    this.verifyId(res.data.guid);
                }
            }
        })
    }

    verifyId = (id) => {
        //login process complete by email without password
        //get guid and verify it
        if(id){
            this.setState({ isIdRequest: true });

            (async () => {

                let ip = await publicIp.v4();
                //=> '46.5.21.123'
                axios.get(Utility.getServerUrl() + '/verifyGuid?id=' + id+'&ip=' + ip)
                .then(res => {
                    const result = res.data;
                    if (result.messageType === "ERROR") {
                        this.setState({ statusError: "Invalid link or expired Url." })
                    }
                    else if (result.messageType === "DBERROR") {
                        this.setState({ statusError: "Database error!" })
                    }
                    else {
                        //store into session
                        GlobalFunction.setMemberID(result.user);
                        window.location.href = "/";
                    }
                });
             
                
            })();
            
        }
    }


    render() {
        const responseGoogle = (response) => {
           this.responseGoogle(response);
        }
        const responseFacebook = (response) => {
           this.responseFacebook(response);
        }
        return (
            <div id="social-modal" className="modal fade" tabIndex="-1" role="dialog"
                aria-labelledby="social-modalLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-body">
                            <button type="button" className="close" data-dismiss="modal" aria-hidden="true" onClick={() => window.$('#social-modal').modal('hide')}>×</button>
                            <p className="text-center pt-2">
                                <img src="/assets/images/logo-county-tax-sales-full-spell-dark.png" alt="" height="40" />
                            </p>
                            <div className="row signup-margin">
                                <div className="col-md-6 text-center">
                                    <i className="fas fa-home" style={{ fontSize: "2.5em" }}></i>
                                    <p className="text-muted mt-2">Buy government owned properties for fraction of their market prices</p>
                                </div>
                                <div className="col-md-6 text-center">
                                    <i className="fas fa-money-bill-alt" style={{ fontSize: "2.5em" }}></i>
                                    <p className="text-muted mt-2">Invest in liens that can yield over 18%</p>
                                </div>
                            </div>
                            <div className="row signup-margin mt-3">
                                <div className="col-md-6">
                                    <GoogleLogin
                                        clientId={Utility.googleClientId()}
                                        buttonText="Login"
                                        onSuccess={responseGoogle}
                                        onFailure={responseGoogle}
                                        cookiePolicy={'single_host_origin'}
                                        render={renderProps => (
                                            <div id="customBtn"  className="customGPlusSignIn" onClick={renderProps.onClick}>
                                                <span className="gicon"></span>
                                                <span className="buttonText">Login with Google</span>
                                            </div>
                                        )}
                                    />
                                </div>
                                <div className="col-md-6">
                                    <FacebookLogin
                                        appId="2820638071519508"
                                        autoLoad={false}
                                        fields="name,email,picture"
                                        callback={responseFacebook}
                                        render={renderProps => (
                                            <div id="customBtn" className="customGPlusSignIn" onClick={renderProps.onClick}>
                                                <span className="fbicon"></span>
                                                <span className="buttonText">Login with Facebook</span>
                                            </div>
                                        )}
                                    />
                                </div>
                            </div>
                            <p className="text-center pt-3">
                                By continuing, I agree that I have read and agree to CountyTaxSales&nbsp;
                                <span className="socialLinks">
                                    <a className="text-blue bottomLink" onClick={() => this.handleTermClick()}>Terms of Service</a>
                                    &nbsp;and&nbsp;
                                    <a className="text-blue bottomLink" onClick={() => this.handlePrivacyClick()}>Privacy Policy</a>
                                </span>
                            </p>

                            <div className="text-center text-white" style={{ position: "relative", top: 60, marginTop:-30 }}>
                                <a className="text-white bottomLink" onClick={()=> this.props.handleLoginClick()}>Log in with Email</a>
                                &nbsp;&nbsp;&nbsp;-&nbsp;&nbsp;&nbsp;
                                <a className="text-white bottomLink" onClick={()=> this.props.handleSignUpClick()}>Sign Up With Email</a>
                            </div>

                        </div>

                    </div>
                </div>
            </div>
        )
    }

}

export default SocialLogin;