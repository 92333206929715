import React, {Fragment} from 'react';


class Faq extends React.Component {
 
    render(){
        var html = this.props.html;
        var title = '';
        let applicationSettingList = this.props.applicationSettingList;
        if (applicationSettingList) {
            applicationSettingList.map(async function (row) {
                if (row.setting_type === "TEXT_FAQ") {
                    html = row.value1;
                    title = row.value2;
                }
            });
        }
        return(
            <div id="faq-modal" className="modal fade" tabIndex="-1" role="dialog"
                aria-labelledby="faq-modalLabel" aria-hidden="true">
                <div className="modal-dialog modal-lg modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-body">
                        <button type="button" className="close" data-dismiss="modal" aria-hidden="true" onClick={() => window.$('#detail-modal').modal('hide')}>×</button>
                            <div  className="text-center">
                                <i className="h1 mdi mdi-comment-multiple-outline text-muted text-center"></i>
                                <h4 className="modal-title" id="faq-modalLabel">{title}</h4>
                            </div>
                            <div dangerouslySetInnerHTML={{ __html: html }} />
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Faq;