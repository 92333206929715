import React from 'react';
import axios from 'axios';
import * as Utility from '../../utilities/Utility'
import * as GlobalFunction from '../../utilities/GlobalFunction'

class Header extends React.Component {
    constructor(props){
        super()
        this.state = {
          member: null,
          credit_balance: 0,
          notification_count: 0,
          notificationList: [],
          isLoadingNotify: true,
        }
    }

    componentDidMount(){
        let member = GlobalFunction.getMember();
        if (member) {
            this.setState({ member: member });
            this.fillMemberData(member.member_id)
          }
    }

    fillMemberData = async(member_id) => {
        axios.get(Utility.getServerUrl() + '/getMemberData?member_id=' + member_id)
          .then(res => {
            if(res.data.member){
              const credit_balance = res.data.member.credit_balance
              if (credit_balance) {
                this.setState({ credit_balance });
              }
              const notification_count = res.data.member.notification_count;
              this.setState({ notification_count });
            }
            const notList = res.data.notification
            this.setState({ notificationList: notList }, () => {
              this.setState({ isLoadingNotify: false })
            });
    
          })
    }

    render() {
        const {member, notification_count, credit_balance} = this.state;
        
        return (
            <>
                <div className="navbar-custom" id="topNavBarDiv">
                    <div className="">
                        <ul className="list-unstyled topnav-menu float-right mb-0">

                            <li className="dropdown d-inline-block d-lg-none">
                                <a className="nav-link dropdown-toggle arrow-none waves-effect waves-light" data-toggle="dropdown" href="#" role="button" aria-haspopup="false" aria-expanded="false">
                                    <i className="fe-search noti-icon"></i>
                                </a>
                                <div className="dropdown-menu dropdown-lg dropdown-menu-right p-0">
                                    <form className="p-3">
                                        <input type="text" className="form-control" placeholder="Search ..." aria-label="Recipient's username" />
                                    </form>
                                </div>
                            </li>

                            {member &&
                                <>
                                    <li className="dropdown d-lg-inline-block">
                                        <div className="nav-link dropdown-toggle arrow-none">
                                            <i className="fas fa-coins"></i>&nbsp;&nbsp;${credit_balance}
                                        </div>
                                    </li>
                                    <li className="dropdown notification-list topbar-dropdown">
                                        <a className="nav-link dropdown-toggle waves-effect waves-light" data-toggle="dropdown" href="#" onClick={() => this.handleNotificationClick()} role="button" aria-haspopup="false" aria-expanded="false">
                                            <i className="fe-bell noti-icon"></i>
                                            {notification_count > 0 &&
                                                <span className="badge badge-danger rounded-circle noti-icon-badge">
                                                    {notification_count}
                                                </span>
                                            }
                                        </a>
                                        <div className="dropdown-menu dropdown-menu-right dropdown-lg">
                                            <div className="dropdown-item noti-title">
                                                <h5 className="m-0">
                                                    Notification
                          </h5>
                                            </div>
                                            <div className="noti-scroll" data-simplebar>
                                                {this.notifyRender()}
                                            </div>
                                        </div>
                                    </li>
                                </>
                            }
                            {this.logoutRender()}
                        </ul>
                        <div className="logo-box">
                            <a href="/" className="logo logo-light text-center">
                                <span className="logo-sm">
                                    <img src="/assets/images/county-tax-sale-icon.png" alt="" height="35" />
                                </span>
                                <span className="logo-lg">
                                    <img src="/assets/images/county-tax-sale-logo.png" alt="" height="35" />
                                    <span style={{ color: "white", fontSize: "15pt", marginLeft: "10px", marginRight: "10px" }}>|</span>
                                    <span style={{ color: "white", fontSize: "12pt" }}>
                                        county tax sales
                                    </span>
                                </span>
                            </a>
                        </div>

                        <ul className="list-unstyled topnav-menu topnav-menu-left m-0">
                            <li>
                                <button className="button-menu-mobile waves-effect waves-light">
                                    <i className="fe-menu"></i>
                                </button>
                            </li>
                            <li>
                                <a className="navbar-toggle nav-link" data-toggle="collapse" data-target="#topnav-menu-content">
                                    <div className="lines">
                                        <span></span>
                                        <span></span>
                                        <span></span>
                                    </div>
                                </a>
                            </li>

                        </ul>
                        <div className="clearfix"></div>
                    </div>
                </div>
                <div className="topnav shadow-lg" id="topNavBarDiv2">
                    <div className="" style={{ paddingLeft: "15px" }}>
                        <nav className="navbar navbar-light navbar-expand-lg topnav-menu">

                            <div className="collapse navbar-collapse" id="topnav-menu-content">
                                <ul className="navbar-nav">
                                    <li className="nav-item">
                                        <a className="nav-link" href="#" role="button" onClick={() => this.props.handleStandardModal('show', '')}>
                                            <i className="fe-tag mr-1"></i> Upcoming Auctions
                                        </a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link" href="#" role="button" onClick={() => this.props.showModal('faq-modal')}>
                                            <i className="fe-help-circle mr-1"></i> FAQ
                                        </a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link" href="#" role="button" onClick={() => this.props.showModal('about-modal')}>
                                            <i className="fe-info mr-1"></i> About Us
                                        </a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link" href="#" role="button" onClick={() => this.props.showModal('contact-modal')}>
                                            <i className="fe-mail mr-1"></i> Contact Us
                                        </a>
                                    </li>
                                    <li className="nav-item mypurple">
                                        <a className="nav-link mypurple" href="#" role="button" onClick={() => this.props.handleReferClick()}>
                                            <i className="fe-dollar-sign mr-1"></i>
                                            Refer a friend, Earn $5
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </nav>
                    </div>
                </div>

            </>
        )
    }

    handleNotificationClick = () => {
        const {member} = this.state;
    
        if (member) {
          axios.post(Utility.getServerUrl() + '/updateNotifictionRead', {
            member_id: member.member_id
          })
            .then(res => {
              if (res.data === "OK") {
                //this.setState({ statusError: res.data.message })
                this.fillMemberData(member.member_id)
              }
            })
        }
      }
    

    logoutRender() {
        const {member} = this.state;
        if (member) {
            return (
                <li className="dropdown notification-list topbar-dropdown">
                    <a className="nav-link dropdown-toggle nav-user mr-0 waves-effect waves-light" data-toggle="dropdown" href="#" role="button" aria-haspopup="false" aria-expanded="false">
                        <img src="/assets/images/users/user-1.png" alt="user-image" className="rounded-circle" />
                        <span className="pro-user-name ml-1">
                            {member.name} <i className="mdi mdi-chevron-down"></i>
                        </span>
                    </a>
                    <div className="dropdown-menu dropdown-menu-right profile-dropdown ">
                        <a href="#" onClick={() => this.handleLogout()} className="dropdown-item notify-item">
                            <i className="fe-log-out"></i>
                            <span>Logout</span>
                        </a>
                    </div>
                </li>
            )
        }
        else {
            return (
                <li>
                    <a className="nav-link nav-user mr-0 waves-effect waves-light"
                        onClick={() => this.props.handlerSocialModal()} role="button">
                        <img src="/assets/images/users/user-1.png" alt="user-image" className="rounded-circle" />
                        <span className="pro-user-name ml-1">
                            Sign In or Join
                        </span>
                    </a>
                </li>
            )
        }
    }

    notifyRender() {
        const {notificationList, isLoadingNotify} = this.state;
        
        if (isLoadingNotify) {
            return (
                <></>
            )
        }
        if (notificationList.length > 0) {
            let arr = [];
            for (var i = 0; i < notificationList.length; i++) {
                let image = "/" + notificationList[i].image;
                arr.push(
                    <a key={i} className="dropdown-item notify-item" style={{ paddingBottom: 0 }}>
                        <div className="notify-icon">
                            <img src={image} className="img-fluid rounded-circle" alt="" />
                        </div>
                        <p className="user-msg">
                            {notificationList[i].details_display}<br />
                            <small className="text-muted">{notificationList[i].create_ts}</small>
                        </p>
                    </a>
                )
            }
            return arr;

        }
        else {
            return (<a href="#" className="dropdown-item notify-item active">
                <p className="text-muted mb-0">
                    <small>No notifications</small>
                </p>
            </a>)
        }
    }

    handleLogout = () =>{
        GlobalFunction.logout();
        window.location.href = "/"
      }

}

export default Header;