import React from 'react'
import axios from 'axios';
import * as Utility from '../../utilities/Utility'
import * as GlobalFunction from '../../utilities/GlobalFunction'


class Login extends React.Component {    
    constructor(props) {
        super();
        this.state = {
            email: '',
            statusError: '',
            isIdRequest: false,
            isEmailValid: false,
            isSocialLogin: false
        }
    }

    componentDidMount(){
        let params = new URLSearchParams(window.location.search);
        let id = params.get('id');
        console.log("id:" + id);     
        this.verifyId(id);
    }

    resetLogin = () => {
        
        console.log("resetLogin");
        this.setState({
            email: '',
            statusError: '',
            isIdRequest: false,
            isEmailValid: false,
            isSocialLogin: false
        });
        GlobalFunction.clearReferShortName();
    }
    
    verifyId = (id) => {
        //login process complete by email without password
        //get guid and verify it
        if(id){
            this.setState({ isIdRequest: true });
            axios.get(Utility.getServerUrl() + '/verifyGuid?id=' + id)
                .then(res => {
                    const result = res.data;
                    if (result.messageType === "ERROR") {
                        this.setState({ statusError: "Invalid link or expired Url." })
                    }
                    else if (result.messageType === "DBERROR") {
                        this.setState({ statusError: "Database error!" })
                    }
                    else {
                        //store into session
                        GlobalFunction.setMemberID(result.user);
                        window.location.href = "/";
                    }
                })
        }
    }

    handleSubmitForm = (e) =>{
        e.preventDefault();
        this.submitLogin();    
    }

    submitLogin = () => {
        const {email, isSocialLogin} = this.state;
        axios.post(Utility.getServerUrl() + '/loginUser', {
            email: email,
            isSocialLogin: isSocialLogin
        })
        .then(res => {
            if (!res.data.messageType || res.data.messageType === "ERROR") {
                this.setState({ statusError: res.data.message })
                //set error
            }
            else {
                if(!isSocialLogin)
                    this.setState({isEmailValid: true});
                else{
                    this.verifyId(res.data.guid);
                }
            }
        })
    }
    
    render(){
        const {email, statusError, isIdRequest, isEmailValid} = this.state;
       
        let url = window.location.pathname;
        let isJoin = false;
        if(url.indexOf("login_email") !== -1){
            isJoin = true;
        }
      
        if(!isIdRequest){
            return(
                <div id="login-modal" className="modal fade" tabIndex="-1" role="dialog"
                    aria-labelledby="login-modalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content">
                            <div className="modal-body">
                                <button type="button" className="close" data-dismiss="modal" aria-hidden="true" onClick={() => window.$('#login-modal').modal('hide')}>×</button>
                                {isEmailValid == true ?
                                    <>
                                        {GlobalFunction.emailDone(this.state.email)}
                                        <p className="text-center">
                                            <button className="btn btn-primary btn-block" type="button" onClick={() => window.$('#login-modal').modal('hide')}> Close </button>
                                        </p>
                                    </>
                                    :
                                    <>
                                        <p className="text-center">
                                            <img src="/assets/images/logo-county-tax-sales-full-spell-dark.png" alt="" height="40" />
                                        </p>
                                        <h5 className="text-center text-muted pt-2">
                                            Log into your account
                                        </h5>
                                        <br />
                                        <form action="#" onSubmit={this.handleSubmitForm}>
                                            <div className="row signup-margin mt-3">
                                                <div className="col-md-12" style={{ paddingLeft: 0 }}>
                                                    <input className="form-control" type="email" id="emailaddress" required placeholder="Email address" onKeyUp={(e) => this.setState({ email: e.target.value })} />
                                                </div>
                                            </div>
                                            <div className="row signup-margin mt-3">
                                                <div className="col-md-12" style={{ paddingLeft: 0 }}>
                                                    {statusError != '' &&
                                                        <span className="text-danger">
                                                            This email is not found. Please <a href="#" onClick={() => this.props.onSignUpClick()} className="text-blue">sign up!</a>
                                                        </span>
                                                    }
                                                </div>
                                            </div>

                                            <div className="row signup-margin mt-3 mb-2">
                                                <div className="col-md-12" style={{ paddingLeft: 0 }}>
                                                    <button className="btn btn-primary btn-block" type="submit"> Log In </button>
                                                </div>
                                            </div>
                                        </form>
                                        <div className="text-center text-white" style={{ position: "relative", top: 60, marginTop: -30 }}>
                                            <a className="text-white bottomLink" onClick={() => this.props.handlerSocialModal()}>Continue w Google or Facebook</a>
                                            &nbsp;&nbsp;&nbsp;-&nbsp;&nbsp;&nbsp;
                                            <a className="text-white bottomLink" onClick={() => this.props.onSignUpClick()}>Sign Up With Email</a>
                                        </div>
                                    </>

                                }
                                
                            </div>
                        </div>
                    </div>
                </div>
            );
        }
        else{
            //isIdRequest == true
            return(
                <div id="login-modal" className="modal fade" tabIndex="-1" role="dialog"
                    aria-labelledby="login-modalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content">
                            <div className="modal-body">
                                    <p className="text-center">
                                        <img src="/assets/images/logo-county-tax-sales-full-spell-dark.png" alt="" height="40" />
                                    </p>
                                    {
                                        statusError === "" ?
                                        <>
                                        <p className="text-center font-weight-bold">
                                            Welcome to County Tax Sales
                                        </p>
                                        <br /><br />
                                        </>
                                        :
                                        <>
                                        <p className="text-center font-weight-bold text-danger">
                                            {statusError}
                                        </p>
                                        {statusError == "Invalid link or expired Url." &&
                                        <p className="text-center" style={{color: "silver", fontWeight: "bolder"}}>
                                            The link you clicked is expired.<br />
                                            Please check for a more recent email for us.
                                        </p>
                                        }
                                        <br /><br />
                                        </>
                                    }
                            </div>
                        </div>
                    </div>
                </div>
            );
        }
        
    }


}    

export default Login;