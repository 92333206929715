import React from 'react'
import axios from 'axios';
import ReactDOMServer from 'react-dom/server';
import * as Utility from '../../utilities/Utility'
import * as GlobalFunction from '../../utilities/GlobalFunction'
import {isMobile} from "react-device-detect";
//import { withRouter} from 'react-router-dom';

let map;
let gmarkers = [];
let markerIcons = [];
let infowindow;
let impIcon;
let latIcon;
let overIcon;
let grayIcon;
let selectedRow;

let auctionList = [];

class Map extends React.Component {    
    constructor(props) {
        super();
    }

    componentDidMount() {
        if (this.props.googleMapsLoaded) {
            //console.log("load")
            setTimeout(() => {
                this.initMap();    
                //console.log("time")
              }, 2000);
            
        }
        infowindow = new window.google.maps.InfoWindow({
            pixelOffset: new window.google.maps.Size(0, 0)
        });
    }
   
    initMap = () => {
        auctionList = this.props.auctionList;
        impIcon = '/pins/dot-red.png';
        latIcon = '/pins/dot-purple.png';
        overIcon = '/pins/dot-green.png';
        grayIcon = '/pins/dot-gray.png';

        var latLng = {lat: -25.363, lng: 131.044};

        map = new window.google.maps.Map(document.getElementById('map'), {
            zoom: 10,
            center: latLng,
            gestureHandling: 'greedy'
        })

        window.google.maps.event.addListener(map, 'click', function () {
            infowindow.close();
        });
    
        this.markerListAdd();
        
        //console.log("after markers");
        
        window.map = map
    }

    markerListAdd = () => {
        var bounds = new window.google.maps.LatLngBounds();
        let fiveRecords = 0;
        auctionList.map((row) => {
            if(row.latitude && row.longitude){
                var lat = parseFloat(row.latitude);
                var lng = parseFloat(row.longitude);
                var point = new window.google.maps.LatLng(lat, lng);
                // create the marker
                var marker = this.createMarker(point, row, fiveRecords);
               
                bounds.extend(marker.position);
                fiveRecords = fiveRecords + 1;
            }
        })
        if(map){
            map.fitBounds(bounds);
        }
    }

    iwDetailsClickHandler = () =>{
        if(selectedRow && selectedRow.auction_item_id){
            this.props.showPropertyDetailModal(selectedRow.auction_item_id)
        }
    }

    iwGoogleClickHandler = () => {
        if(selectedRow){
            let param = selectedRow.property_address
            if(selectedRow.zip){
                param = selectedRow.property_address + '+' + selectedRow.zip 
            }
            if(selectedRow.city){
                param = param + '+' + selectedRow.city
            }
            if(selectedRow.state_long){
                param = param + '+' + selectedRow.state_long
            }
            window.open('https://www.google.com/search?q=' + param, '_blank');
        }
    }

    iwCardClickHandler = () => {
        if(selectedRow && selectedRow.ext_link1_url){
            let ext_link1_url  = selectedRow.ext_link1_url
            window.open(ext_link1_url);
        }
    }

    mapLoginClickHandler = () => {
        this.props.handlerSocialModal();
    }

    noAccessClickHandler = () => {
        window.$('#noaccess-modal').modal('show');
    }

    accesscreditClickHandler = () => {
        window.$('#access-credit-modal').modal('show');
    }

    createMarker = (latlng, row, fiveRecords) => {
        let normalIcon = impIcon;
        var contentString = '';

        if(row.lot_or_imp == "Lot"){
            normalIcon = latIcon
        }
        var member = this.props.member

        if (member) {
            // if(fiveRecords > this.props.notLoggedInRows - 1 && this.props.isActionMemberFound){

            // }
            if(fiveRecords <= this.props.notLoggedInRows - 1){
                contentString = this.mapPinDataRender(row);
            }
            else{
                if(this.props.isActionMemberFound || !this.props.selectedAuctionIsUpcoming){
                    contentString = this.mapPinDataRender(row);
                }
                else{
                    normalIcon = grayIcon
                    contentString = "You currently do not have access.<br /><a href='#' onclick='document.getElementById(\"accesscredit\").click();'>Access this data</a><br /><br />" +
                                    "<b>Min Bid:</b> " + GlobalFunction.currencyFormat2(row.min_bid) + "<br />";
                }
            }
        }
        else{
            if(fiveRecords <= this.props.notLoggedInRows - 1){
                contentString = this.mapPinDataRender(row);
            }
            else{
                normalIcon = grayIcon
                contentString = "<a href='#' onclick='document.getElementById(\"map_Login\").click();'>Log in to see details</a><br /><br />" +
                                "<b>Min Bid:</b> " + GlobalFunction.currencyFormat2(row.min_bid) + "<br />";
            }
        }
        
        var marker = new window.google.maps.Marker({
            position: latlng,
            icon: normalIcon,
            map: map,
            //title: row.property_address,
        });
    
        window.google.maps.event.addListener(marker, 'click', () => {
            selectedRow = row;
            //const content = ReactDOMServer.renderToString(this.renderInfoWindow());   
            infowindow.setContent(contentString);
            infowindow.open(map, marker);
            //this.props.showPropertyDetailModal(row.auction_item_id)
        });
        window.google.maps.event.addListener(marker, "mouseover", () => {     
            selectedRow = row;
            if(!isMobile){
                marker.setIcon(overIcon);
                infowindow.setContent(contentString);
                infowindow.open(map, marker);
                
                this.props.onMouseOverMarker(row.auction_item_id);
            }
        });
        window.google.maps.event.addListener(marker, "mouseout", () => {           
            if(!isMobile){
                marker.setIcon(normalIcon);
                //marker.setLabel(null)
                // if (infowindow) {
                //     infowindow.close();
                // }          
                this.props.onMouseOutMarker(row.auction_item_id);
            }
        });

        gmarkers.push(marker);
        markerIcons.push(normalIcon)

        return marker;
    }

    mapPinDataRender(row){
        var contentString = '';
        let zillow = '';
        let trulia = '';

        if (row.zillow_price_est) {
            zillow = "<b>Zillow price estimate:</b> " + GlobalFunction.currencyFormat(row.zillow_price_est) + "<br />"
        }

        if (row.trulia_crime_rating) {
            trulia = "<div style=\"margin-bottom:5px\"><b>Trulia crime rating:</b> " + row.trulia_crime_rating + "<div>"
        }

        let zip = '';
        if (row.zip) {
            zip = "<div style=\"margin-bottom:5px\"><b>Zip:</b> " + row.zip + "</div>"
        }
        let ass_land_value = '';
        if (row.ass_land_value) {
            ass_land_value = "<div style=\"margin-bottom:5px\"><b>Assessed Land value:</b> " + GlobalFunction.currencyFormat2(row.ass_land_value) + "</div>"
        }
        let ass_imp_value = '';
        if (row.ass_imp_value) {
            ass_imp_value = "<div style=\"margin-bottom:5px\"><b>Assessed Improvement value:</b> " + GlobalFunction.currencyFormat2(row.ass_imp_value) + "</div>"
        }
        let ass_total_value = '';
        if (row.ass_total_value) {
            ass_total_value = "<div style=\"margin-bottom:5px\"><b>Assessed Total value:</b> " + GlobalFunction.currencyFormat2(row.ass_total_value) + "</div>"
        }

        contentString = "<div class='mapMarkeraddress'><strong><b>" + row.property_address + "</b></strong></div>" +
            "<div style=\"margin-bottom:5px\"><b>Min Bid:</b> <span class='mapMarkerAmount'>" + GlobalFunction.currencyFormat2(row.min_bid) + "</span></div>" +
            zip + ass_land_value + ass_imp_value + ass_total_value + zillow + trulia;
        let pCardHtml = '';
        if (row.ext_link1_url && row.ext_link1_label) {
            pCardHtml = "<input type='button' class='btn btn-sm btn-outline-primary' value='" + row.ext_link1_label + "'  onclick='document.getElementById(\"iw_Card\").click();' />"
        }
        contentString = contentString +
            "<div style=\"margin-top:10px\">" +
            "<input type='button' class='btn btn-sm btn-outline-primary' value='Details' onclick='document.getElementById(\"iw_Details\").click();' />&nbsp;&nbsp;" +
            "<input type='button' class='btn btn-sm btn-outline-primary' value='Google' onclick='document.getElementById(\"iw_Google\").click();' />&nbsp;&nbsp;" +
            pCardHtml +
            "</div>";
        return contentString;
    }

    handleRowMouseOver = (index) => {
        if(gmarkers.length > 0 && map){
            var zoomLevel = map.getZoom();
            if (zoomLevel>13){
                map.setZoom(14);
            }
            map.panTo(gmarkers[index].getPosition());
            window.google.maps.event.trigger(gmarkers[index], 'mouseover');

            window.google.maps.event.trigger(gmarkers[index], "click");
        }
    }

    handleRowMouseOut = (index) => {
        window.google.maps.event.trigger(gmarkers[index], 'mouseout');
        if (infowindow) {
            infowindow.close();
        }
    }

    handlePageChange = (_auctionList) => {
        console.log("change" + _auctionList.length)
        for (var i = 0; i < gmarkers.length; i++) {
            gmarkers[i].setMap(null);
        }
        gmarkers = [];
        markerIcons = [];
        /////////////////////////////////////////////////////////
        auctionList = _auctionList;
        //console.log(auctionList[0].auction_item_id)
        this.markerListAdd();
        
    }
    
    render() {
        return (
            <>
                <div id='map' style={{ width: '100%', height: this.props.mapHeight }}>
                </div>
                <button id="iw_Details" style={{display:'none'}} onClick={() => this.iwDetailsClickHandler()} />
                
                <button id="iw_Google" style={{display:'none'}} onClick={() => this.iwGoogleClickHandler()} />
                <button id="iw_Card" style={{display:'none'}} onClick={() => this.iwCardClickHandler()} />
                <button id="map_Login" style={{display:'none'}} onClick={() => this.mapLoginClickHandler()} />
                <button id="noaccess" style={{display:'none'}} onClick={() => this.noAccessClickHandler()} />
                <button id="accesscredit" style={{display:'none'}} onClick={() => this.accesscreditClickHandler()} />
                
            </>
        )
    }
}

export default Map;