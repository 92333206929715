import React from 'react';
import * as GlobalFunction from '../../utilities/GlobalFunction'

class PropertyDetail extends React.Component{

    render(){
        let selectedAuctionItemId = this.props.selectedAuctionItemId;
        let auctionItemList = this.props.auctionItemList;

        if(selectedAuctionItemId > 0){
            const result = auctionItemList.find(item => item.auction_item_id === selectedAuctionItemId);
            if(result){
                let address = result.property_address;
                if(result.city){
                    address = address + ', ' + result.city
                }
                if(result.state){
                    address = address + ', ' + result.state
                }
                if(result.zip){
                    address = address + ', ' + result.zip
                }
                return(
                    <div id="property-detail-modal" className="modal fade" tabIndex="-1" role="dialog"
                        aria-labelledby="property-detail-modalLabel" aria-hidden="true">
                        <div className="modal-dialog modal-lg modal-dialog-centered">
                            <div className="modal-content">
                                <div className="modal-body">
                                    <button type="button" className="close" data-dismiss="modal" aria-hidden="true" onClick={() => window.$('#detail-modal').modal('hide')}>×</button>
                                    <h3 style={{color:"#2e75c2"}}>{address}</h3><br />
                                    {   result.min_bid &&
                                        <div className="row pb-1">
                                            <div className="col-md-4 property-detail-caption">
                                                Minimum Bid:
                                            </div>
                                            <div className="col-md-6 property-detail-value pl-2">
                                                 {GlobalFunction.currencyFormat2(result.min_bid)}
                                            </div>
                                        </div>
                                    }
                                    {
                                        result.ass_land_value > 0 &&
                                        <div className="row pb-1">
                                            <div className="col-md-4 property-detail-caption">
                                                Assessed Land Value:
                                            </div>
                                            <div className="col-md-6 property-detail-value pl-2">
                                                 {GlobalFunction.currencyFormat2(result.ass_land_value)}
                                            </div>
                                        </div>
                                    }
                                    {
                                        result.ass_imp_value > 0 &&
                                        <div className="row pb-1">
                                            <div className="col-md-4 property-detail-caption">
                                                Assessed Improvement Value:
                                            </div>
                                            <div className="col-md-6 property-detail-value pl-2">
                                                 {GlobalFunction.currencyFormat2(result.ass_imp_value)}
                                            </div>
                                        </div>
                                    }
                                    {
                                        result.ass_total_value > 0 &&
                                        <div className="row pb-1">
                                            <div className="col-md-4 property-detail-caption">
                                            	Assessed Total Value:
                                            </div>
                                            <div className="col-md-6 property-detail-value pl-2">
                                                 {GlobalFunction.currencyFormat2(result.ass_total_value)}
                                            </div>
                                        </div>
                                    }


                                    {
                                        result.tax_sale_id &&
                                        <div className="row pb-1">
                                            <div className="col-md-4 property-detail-caption">
                                                Tax Sale Id:
                                            </div>
                                            <div className="col-md-6 property-detail-value pl-2">
                                                 {result.tax_sale_id}
                                            </div>
                                        </div>
                                    }
                                    {
                                        result.parcel_id &&
                                        <div className="row pb-1">
                                            <div className="col-md-4 property-detail-caption">
                                                Parcel Id:
                                            </div>
                                            <div className="col-md-6 property-detail-value pl-2">
                                                 {result.parcel_id}
                                            </div>
                                        </div>
                                    }
                                    {
                                        result.legal_desc &&
                                        <div className="row pb-1">
                                            <div className="col-md-4 property-detail-caption">
                                                Legal Desc:
                                            </div>
                                            <div className="col-md-8 property-detail-value pl-2">
                                                 {result.legal_desc}
                                            </div>
                                        </div>
                                    }
                                    {
                                        result.owner &&
                                        <div className="row pb-1">
                                            <div className="col-md-4 property-detail-caption">
                                                Owner:
                                            </div>
                                            <div className="col-md-8 property-detail-value pl-2">
                                                 {result.owner}
                                                 {result.owner_address &&
                                                    <>
                                                    <br />{result.owner_address}
                                                    </>
                                                 }
                                            </div>
                                        </div>
                                    }
                                    <div className="pt-2">
                                        {
                                            result.ext_link1_url && result.ext_link1_label &&
                                            <span className="pr-1">
                                                <input type='button' className='btn btn-sm btn-outline-primary' value={result.ext_link1_label} onClick={() => this.iwCardClickHandler(result.ext_link1_url)} />
                                            </span>
                                        }
                                        <input type='button' className='btn btn-sm btn-outline-primary' value='Google' onClick={() => this.iwGoogleClickHandler(result)} />
                                    </div>
                                    <br/>
                                    <h3>Auction Details</h3>
                                    <span className=" property-detail-caption">{result.name}, {result.state}</span><br />
                                    {
                                        result.type && 
                                        <>
                                            <span className=" property-detail-caption">{result.type} Auction</span><br />
                                        </>
                                    }
                                    {
                                        result.dt_of_auction &&
                                        <>
                                            <span className=" property-detail-caption">{result.dt_of_auction}</span><br />
                                        </>
                                    }
                                    {
                                        result.ext_url_info &&
                                        <div className="pt-2">
                                            <a className="btn btn-outline-primary waves-effect waves-light" target="_blank" href={result.ext_url_info}>
                                                <i className="mdi mdi-latitude mr-1"></i> Go to County Website
                                            </a>
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                )
            }
        }
        return(
            <></>
        )
    }

    iwCardClickHandler = (ext_link1_url) => {
        if(ext_link1_url){
         window.open(ext_link1_url);
        }
     }

     iwGoogleClickHandler = (selectedRow) => {
        let param = selectedRow.property_address
        if(selectedRow.zip){
            param = selectedRow.property_address + '+' + selectedRow.zip 
        }
        if(selectedRow.city){
            param = param + '+' + selectedRow.city
        }
        if(selectedRow.state_long){
            param = param + '+' + selectedRow.state_long
        }
        window.open('https://www.google.com/search?q=' + param, '_blank');
    }
 
}


export default PropertyDetail;